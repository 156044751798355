<template>
  <div class="form-group">
    <form-label required>
      {{ label }}
    </form-label>
    <d-row>
      <d-col
        v-for="test in tests"
        :key="test.code"
        :class="{
          'test-picker d-flex d-flex-column': true,
          'test-picker-active d-flex d-flex-column': isTestSelected(test.code),
        }"
        sm="6"
      >
        <div class="p-2 mb-1 mt-1 optimus-test-picker-block">
          <d-row class="align-items-center">
            <d-col sm="12" md="4">
              <d-form-radio
                :id="`umbrella-radio-${test.id}`"
                v-model="umbrellaId"
                :value="test.id"
                :disabled="isDisabledThisTest(test)"
                @input="setUmbrellaId($event)"
                @change="
                  ($event) => ($event ? setUmbrellaId($event) : resetAll())
                "
              >
                <study-test-pill
                  class="m-0 p-0 d-inline custom-text-optimus"
                  :study-test="null"
                  :name="test.name"
                />
                <br />
                <br />
                <p class="m-0 p-0 d-inline custom-text-optimus">
                  {{ test.genes.length }} Genes por NGS
                </p>
                <p
                  v-if="test.biomarkersList.length && !is_only_NGS"
                  class="m-0 p-0 d-inline custom-text-optimus"
                >
                  +
                  {{
                    getFormattedText(test.biomarkersList.map((bmk) => bmk.name))
                  }}
                  por IHQ
                </p>
                <span v-if="isRecentBio(test.code)">
                  <svg
                    id="tooltip"
                    width="30"
                    class="ml-2"
                    height="12"
                    viewBox="0 0 25 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.8979 10.8675L22.0366 5.9025L24.8687 1.14C24.936 1.02651 24.9727 0.896703 24.9751 0.763858C24.9775 0.631014 24.9455 0.499901 24.8823 0.383934C24.8192 0.267968 24.7273 0.171311 24.6159 0.103852C24.5045 0.0363923 24.3776 0.000552859 24.2483 0H1.45986C1.07268 0 0.701361 0.158036 0.427584 0.43934C0.153806 0.720645 0 1.10218 0 1.5L0 10.5C0 10.8978 0.153806 11.2794 0.427584 11.5607C0.701361 11.842 1.07268 12 1.45986 12H24.2702C24.399 12 24.5256 11.9649 24.6369 11.8984C24.7483 11.8318 24.8406 11.7362 24.9043 11.6212C24.9681 11.5061 25.0011 11.3758 25 11.2435C24.9988 11.1111 24.9636 10.9814 24.8979 10.8675ZM7.67157 8.385H6.85405L4.47448 5.13V8.3925H3.64965V3.75H4.47448L6.86135 7.0125V3.75H7.67887L7.67157 8.385ZM12.292 4.5H9.71538V5.6175H12.0512V6.3675H9.71538V7.575H12.292V8.325H8.89056V3.75H12.2847L12.292 4.5ZM18.3432 8.37H17.5183L16.3869 4.9275L15.2556 8.385H14.438L12.9782 3.75H13.8687L14.8322 7.0725L15.9417 3.75H16.8322L17.8979 7.0725L18.8687 3.75H19.7665L18.3432 8.37Z"
                      fill="#FFB800"
                    />
                  </svg>
                  <d-tooltip target="#tooltip" container="true">
                    Hemos habilitado un nuevo Panel.
                  </d-tooltip>
                </span>
              </d-form-radio>
            </d-col>

            <p
              v-if="isDisabledThisTest(test)"
              class="text-danger"
              style="padding-left: 3%"
            >
              El cupo de tests asignado por el sponsor para este mes ha llegado
              al máximo permitido. Para resolver este problema puede ponerse en
              contacto con Biomakers al mail:
              <a class="text-danger" href="mailto:laboratorio@biomakers.net">
                laboratorio@biomakers.net</a
              >
              o teléfono 0800-345-1775.
            </p>

            <d-col sm="12" md="8">
              <d-form-select
                v-if="isTestSelected(test)"
                :id="`pharmaSelect${test.id}`"
                :value="isTestSelected(test).pharmaId"
                class="pharma"
                :options="umbrellapharmaOptionsFor(test)"
                @input="
                  setTestPharma({
                    code: test.code,
                    value: $event,
                    genes: test.genes,
                  })
                "
                @change="
                  setTestPharma({
                    code: test.code,
                    value: $event,
                    genes: test.genes,
                  })
                "
              >
                <option :value="null" disabled>Seleccione programa</option>
              </d-form-select>
            </d-col>

            <d-col>
              <ul
                v-if="isTestSelected(test)"
                class="custom-text-optimus list-group container-genes"
              >
                <li
                  class="
                    custom-text-optimus
                    list-group-item list-group-primary-item
                  "
                >
                  Copynumber genes:
                  {{ copynumberGenes(isTestSelected(test)) }}
                </li>
                <li
                  class="
                    custom-text-optimus
                    list-group-item list-group-primary-item
                  "
                >
                  Fusion: {{ fusionGenes(isTestSelected(test)) }}
                </li>
                <li
                  class="
                    custom-text-optimus
                    list-group-item list-group-primary-item
                  "
                >
                  Hot spot Genes: {{ hotspotGenes(isTestSelected(test)) }}
                </li>
              </ul>
            </d-col>
            <!-- Biomarkers configuration -->

            <div
              v-if="
                isTestSelected(test) &&
                isTestSelected(test).pharmaId &&
                test.biomarkersList.length
              "
              class="col-11"
            >
              <hr style="color: (0, 0, 0, 0)" />
              <p>Biomarcadores</p>
              <div class="row-10">
                <div
                  v-for="(item, index) in test.biomarkersList"
                  :key="index"
                  class="list-group-item custom-active"
                >
                  <d-row class="align-items-center">
                    <d-col sm="12" md="4">
                      <d-form-checkbox
                        :id="`bmkCheckbox${item.id}`"
                        v-model="selectedBiomarkers"
                        :value="item.code"
                        @input="($event) => setUpTests($event)"
                        @change="
                          setBiomarkersPharma({
                            code: item.code,
                            value: null,
                          })
                        "
                      >
                        <h6 class="m-0 p-0 d-inline-block">
                          {{ item.name }}
                        </h6>
                      </d-form-checkbox>
                      <d-form-select
                        v-if="isBmkSelected(item)"
                        :id="`bmkSelect${item.id}`"
                        class="pharma"
                        :value="isBmkSelected(item).pharmaId"
                        :options="
                          actualPharmaOptionsForThisBiomarker(item.pharmas)
                        "
                        @change="
                          setBiomarkersPharma({
                            code: item.code,
                            value: $event,
                          })
                        "
                      >
                        <option :value="null" disabled>
                          Seleccione programa
                        </option>
                      </d-form-select>
                    </d-col>
                  </d-row>
                </div>
              </div>
            </div>
          </d-row>
        </div>
      </d-col>
    </d-row>
    <p v-if="!testsAreValid" class="text-center text-danger">
      Faltan seleccionar programas para algunos estudios
    </p>
  </div>
</template>
<script>
import FormLabel from './Label'
import { shuffle } from '../../utils'
import axios from 'axios'
import StudyTestPill from '../StudyTestPill.vue'

export default {
  name: 'OptimusTestPicker',
  components: {
    FormLabel,
    StudyTestPill,
  },
  props: {
    tests: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Estudios a realizar',
    },
    availableBiomarkers: {
      type: Array,
      default: () => [],
    },
    primaryTumorId: {
      type: Number,
      default: null,
    },
    sampleTypeId: {
      type: Number,
      default: null,
    },
    unavailableTests: {
      type: Array,
      default: () => [],
    },
    is_only_NGS: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedUmbrellas: [],
      selectedTests: [], // this exists only to leverage vue's checkbox models
      selectedTestsWithPharmas: [],
      recentBiomakers: [],
      umbrellaId: null,
      selectedBiomarkers: [],
      selectedBiomarkersWithPharmas: [],
      currentSelectedUmbrellaInfo: {},
      hookExecuted: false,
    }
  },
  computed: {
    testsAreValid() {
      return this.selectedTestsWithPharmas.every((item) => item.pharmaId)
    },
    currentAvailableBiomarkersForThisPanel() {
      return this.availableBiomarkers.filter((configs) =>
        this.selectedBiomarkers.includes(configs.code)
      )
    },
  },
  watch: {
    selectedTests: {
      handler: function (newTests) {
        // console.log('soy newTests de selectedTests')
        this.selectedTests = newTests
        const filteredData = newTests.map((testCode) => {
          const existingItem = this.selectedTestsWithPharmas.find(
            (test) => test.testCode === testCode
          )
          const gene = this.tests
            .find((test) =>
              test.genes.find((gen) => gen.genes.name === testCode)
            )
            .genes.find((g) => g.genes.name === testCode)

          // console.log('watcher', this.selectedTestsWithPharmas, existingItem)

          if (!existingItem)
            return {
              testCode: gene.genes.code,
              geneTestId: gene.gene_id,
              requiresValidationWithNewApi:
                this.selectedUmbrellas[0].requiresValidationWithNewApi,
              pharmaId: null,
              umbrellaId: this.umbrellaId,
            }
          return {
            ...existingItem,
            geneTestId: gene.id,
            requiresValidationWithNewApi:
              this.selectedUmbrellas[0].requiresValidationWithNewApi,
          }
        })
        // console.log('filtro', [...filteredData])
        this.selectedTestsWithPharmas = [...filteredData]
      },
      deep: true,
    },
    selectedBiomarkers: {
      handler: function (newTests) {
        // console.log('soy newTests de selectedBiomarkers', newTests)
        this.selectedBiomarkers = newTests
        const filteredData = newTests.map((testCode) => {
          const existingItem = this.selectedBiomarkersWithPharmas.find(
            (test) => test.testCode === testCode
          )
          const biomarker = this.tests
            .find((test) =>
              test.biomarkersList.find((bmk) => bmk.code === testCode)
            )
            .biomarkersList.find((bk) => bk.code === testCode)
          if (!existingItem)
            return { testCode, testId: biomarker.id, pharmaId: null }
          return { ...existingItem, testId: biomarker.id }
        })
        this.selectedBiomarkersWithPharmas = [...filteredData]
      },
      deep: true,
    },
    selectedBiomarkersWithPharmas: {
      handler: function (newBmks, oldbmks) {
        // console.log(
        //   'soy newTests de selectedBiomarkersWithPharmas',
        //   newBmks,
        //   oldbmks
        // )
        if (newBmks.length >= oldbmks.length) {
          // console.log('caso1')
          for (let index = 0; index < newBmks.length; index++) {
            const element = newBmks[index]
            if (
              !this.selectedTestsWithPharmas.find(
                (test) => test.testCode === element.testCode
              )
            ) {
              this.selectedTestsWithPharmas.push(element)
            }
          }
        }
        if (newBmks.length < oldbmks.length) {
          // console.log('caso2')

          let spliceElement = oldbmks.find(
            (element) =>
              !newBmks.find((otherEl) => otherEl.testCode === element.testCode)
          )
          let idx = this.selectedTestsWithPharmas.findIndex(
            (el) => el.testCode === spliceElement.testCode
          )
          this.$emit('tests-changed', this.selectedTestsWithPharmas)
          if (
            this.selectedTestsWithPharmas.find(
              (test) => test.testCode === spliceElement.testCode
            )
          ) {
            this.selectedTestsWithPharmas.splice(idx, 1)
          }
        }
        if (newBmks.length == oldbmks.length) {
          // console.log('caso3')

          for (let index = 0; index < newBmks.length; index++) {
            const element = newBmks[index]
            if (element.pharmaId) {
              let idx = this.selectedTestsWithPharmas.findIndex(
                (el) => el.testCode === element.testCode
              )
              this.selectedTestsWithPharmas.splice(idx, 1, element)
            }
          }
        }
      },
      deep: true,
    },
    selectedTestsWithPharmas: {
      handler: function (newvalue, value) {
        // console.log('soy newTests de selectedTestsWithPharmas', newvalue, value)
        this.$emit('tests-changed', newvalue)
        if (JSON.stringify(newvalue) != JSON.stringify(value)) {
          // console.log('emito newValue', newvalue)
          this.$emit('tests-changed', newvalue)
        }
      },
      deep: true,
    },
    isTestSelected: {
      handler(value) {
        this.isTestSelected = value
      },
    },
  },
  async created() {
    const result = await axios
      .get(`/api2/biomarker/recent`)
      .then((response) => response.data)
    result.forEach((elem) => this.recentBiomakers.push(elem.code))
  },
  updated() {
    if (
      !this.hookExecuted &&
      Object.keys(this.currentSelectedUmbrellaInfo).length > 0
    ) {
      this.selectSpecificBiomarkersTest(
        this.currentSelectedUmbrellaInfo.biomarkersList
      )
      this.hookExecuted = true
    }
  },
  methods: {
    selectSpecificBiomarkersTest(arrayOfTests) {
      // console.log('soy selectSpecificBiomarkersTest', arrayOfTests)
      let newArrayOfBiomarkers = this.selectedBiomarkers
      for (let index = 0; index < arrayOfTests.length; index++) {
        const biomarker = arrayOfTests[index]
        if (!this.selectedBiomarkers.find((bmk) => bmk === biomarker.code)) {
          newArrayOfBiomarkers.push(biomarker.code)
        }
      }
      this.selectedBiomarkers = [...newArrayOfBiomarkers]
    },
    isDisabledThisTest({ code }) {
      if (this.unavailableTests.find((test) => test.code == code)) {
        return true
      }
    },
    resetAll() {
      // console.log('activo resetAll')
      this.setUmbrellaId(null)
      this.selectedBiomarkers = []
      this.selectedTests = []
      this.selectedBiomarkersWithPharmas = []
      this.selectedTestsWithPharmas = []
    },
    hotspotGenes(test) {
      if (test)
        return this.getFormattedText(
          test.genes
            .filter((gen) => gen.category.includes('hotspot'))
            .map((obj) => obj.genes.name)
        )
    },
    fusionGenes(test) {
      if (test)
        return this.getFormattedText(
          test.genes
            .filter((gen) => gen.category.includes('fusion'))
            .map((obj) => obj.genes.name)
        )
    },
    copynumberGenes(test) {
      if (test)
        return this.getFormattedText(
          test.genes
            .filter((gen) => gen.category.includes('copynumber'))
            .map((obj) => obj.genes.name)
        )
    },
    actualPharmaOptionsForThisBiomarker(pharmas) {
      return pharmas.map((ph) => ({
        ...ph,
        value: ph.id,
        text: ph.name,
      }))
    },
    setUpTests(tests) {
      this.selectedBiomarkers = tests
    },

    isRecentBio(testCode) {
      return JSON.parse(JSON.stringify(this.recentBiomakers)).includes(testCode)
    },

    pharmaOptionsFor({ allowedPharmas }) {
      //it's being used for bmks!
      let pharmas = allowedPharmas.map((pharma) => ({
        ...pharma,
        value: pharma.id,
        text: pharma.name,
      }))
      return pharmas
    },
    isTestSelected({ code }) {
      return this.selectedUmbrellas.find(
        (umbrella) =>
          umbrella.testId == this.umbrellaId && umbrella.testCode === code
      )
    },
    isBmkSelected({ code }) {
      return this.selectedBiomarkersWithPharmas.find(
        (bmk) => bmk.testCode === code
      )
    },
    isUmbrellaSelected() {
      if (this.umbrellaId != null) return true
    },
    setPharmaInSelectedTestWithPharma({ genes, pharmaId }) {
      // console.log('soy setPharmaInSelectedTestWithPharma', genes, pharmaId)
      let newTests = []
      for (let index = 0; index < genes.length; index++) {
        const element = genes[index]
        let actualGene = {
          geneTestId: element.gene_id,
          testCode: element.genes.code,
          pharmaId: pharmaId,
          umbrellaId: this.umbrellaId,
          requiresValidationWithNewApi:
            this.selectedUmbrellas[0].requiresValidationWithNewApi,
        }
        if (!newTests.includes(actualGene)) newTests.push(actualGene)
      }
      if (
        !this.selectedTestsWithPharmas.find((bmkTest) => bmkTest.testId) &&
        JSON.stringify(this.selectedTestsWithPharmas) !==
          JSON.stringify(newTests)
      ) {
        this.selectedTestsWithPharmas = newTests
      }
    },
    umbrellapharmaOptionsFor({ code, allowedPharmas, genes }) {
      let pharmas = allowedPharmas
        .map((ph) => ({
          ...ph,
          umbrellaCustomSettings: ph.umbrellaCustomSettings.filter(
            (setting) =>
              setting.primaryTumorId === this.primaryTumorId &&
              setting.sampleTypeId == this.sampleTypeId
          ),
          value: ph.id,
          text: ph.name,
        }))
        .filter((setting) => setting.umbrellaCustomSettings.length)

      if (pharmas.length === 1 && this.isUmbrellaSelected()) {
        let pharmaId = pharmas[0].id
        this.isTestSelected({ code }).pharmaId = pharmas[0].id
        this.setPharmaInSelectedTestWithPharma({
          genes: genes,
          pharmaId: pharmaId,
        })
      }

      if (pharmas.length > 1) {
        pharmas = shuffle(pharmas)
      }
      return pharmas
    },
    setTestPharma({ code, value, genes }) {
      const newPharmas = [...this.selectedTestsWithPharmas]
      // console.log('soy setTestPharma', value, code, genes)
      for (let index = 0; index < genes.length; index++) {
        const element = genes[index]
        const idx = this.selectedTestsWithPharmas.findIndex(
          (test) => test.testCode === element.genes.code
        )
        newPharmas.splice(idx, 1, {
          ...newPharmas[idx],
          geneTestId: element.gene_id,
          pharmaId: value,
        })
      }
      this.isTestSelected({ code }).pharmaId = value
      this.selectedTestsWithPharmas = newPharmas
    },
    setBiomarkersPharma({ code, value }) {
      // console.log('activo setBiomarkersPharma', code, value)
      const newPharmas = [...this.selectedBiomarkersWithPharmas]
      for (
        let index = 0;
        index < this.selectedBiomarkersWithPharmas.length;
        index++
      ) {
        const element = this.selectedBiomarkersWithPharmas[index]
        if (element.testCode === code)
          newPharmas.splice(index, 1, {
            ...newPharmas[index],
            pharmaId: value,
          })
      }
      if (value != undefined) this.isBmkSelected({ code }).pharmaId = value
      this.selectedBiomarkersWithPharmas = newPharmas
    },
    setUmbrellaId(id) {
      this.umbrellaId = id
      this.$emit('set_umbrella_id', id)
      this.selectedTests = []
      this.selectedTestsWithPharmas = []
      this.selectedUmbrellas = []
      this.selectedBiomarkers = []
      this.hookExecuted = false
      if (id) {
        let test = this.tests.find((test) => test.id == id)
        test.genes.forEach((gene) => this.selectedTests.push(gene.genes.name))
        this.currentSelectedUmbrellaInfo = test
        if (this.isTestSelected(test)) {
          if (
            !this.selectedUmbrellas.find(
              (u) =>
                u ==
                {
                  testCode: test.code,
                  testId: test.id,
                  pharmaId: this.isTestSelected(test).pharmaId,
                  genes: test.genes,
                }
            )
          ) {
            this.selectedUmbrellas = []
            this.selectedUmbrellas.push({
              testCode: test.code,
              testId: test.id,
              pharmaId: this.isTestSelected(test).pharmaId,
              requiresValidationWithNewApi: test.requiresValidationWithNewApi,
              genes: test.genes,
            })
          }
        } else {
          this.selectedUmbrellas.push({
            testCode: test.code,
            testId: test.id,
            pharmaId: null,
            requiresValidationWithNewApi: test.requiresValidationWithNewApi,
            genes: test.genes,
          })
        }
      } else {
        this.selectedUmbrellas = []
      }
    },
    getFormattedText(array) {
      let k = ''
      for (let index = 0; index < array.length; index++) {
        const element = array[index]
        k = k + ' ' + element + ','
      }
      return k
    },
    setBiomarkerTestPharma({ code, value }) {
      // console.log('activo setBiomarkerTestPharma', code, value)
      const idx = this.selectedTestsWithPharmas.findIndex(
        (test) => test.testCode === code
      )
      if (this.selectedTestsWithPharmas[idx].pharmaId !== value)
        delete this.selectedTestsWithPharmas[idx].customEnvelopeCode
      const newPharmas = [...this.selectedTestsWithPharmas]
      newPharmas.splice(idx, 1, {
        ...newPharmas[idx],
        pharmaId: value,
      })
      this.selectedTestsWithPharmas = newPharmas
    },
  },
}
</script>

<style lang="scss">
custom-active {
  background-color: #f9f9ff;
  border: 1px solid #afa8f3;
}
.test-picker .optimus-test-picker-block {
  border: 1px solid #efedfd;
  border-radius: 4px;
  box-sizing: border-box;
  width: 200%;
}

.test-picker-active .optimus-test-picker-block {
  background-color: #f9f9ff;
  border: 1px solid #afa8f3;
}

.test-picker-active
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border: 1px solid #fff;
}
.custom-text-optimus {
  color: var(--dark-blue-2);
}
.container-genes {
  border-color: var(--dark-blue-2) 3;
}
</style>

import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'
import Login from '@/views/Login.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import Register from '@/views/Register.vue'
import LoginList from '@/views/Management/LoginList.vue'
import OAuthCredentials from '@/views/Management/OAuthCredentials.vue'
import BulkStudiesLoad from '@/views/Management/BulkStudiesLoad.vue'
import Tumors from '@/views/Management/TumorsIndex.vue'
import Tumor from '@/views/Management/Tumor.vue'
import Biomarkers from '@/views/Management/BiomarkersIndex.vue'
import Biomarker from '@/views/Management/Biomarker.vue'
import Countries from '@/views/Management/CountriesIndex.vue'
import Country from '@/views/Management/Country.vue'
import Pharmas from '@/views/Management/PharmasIndex.vue'
import Pharma from '@/views/Management/Pharma.vue'
import Labs from '@/views/Management/Labs.vue'
import Lab from '@/views/Management/Lab.vue'
import Panels from '@/views/Management/Panels.vue'
import PanelCreate from '@/views/Management/PanelCreate.vue'
import Genes from '@/views/Management/Genes.vue'
import GenesPanelsandUmbrellas from '@/views/Management/GenesPanelsandUmbrellas.vue'
import Umbrellas from '@/views/Management/Umbrellas.vue'
import UmbrellaCreate from '@/views/Management/UmbrellaCreate.vue'
import Results from '@/views/Management/Results.vue'
import Settings from '@/views/Settings.vue'
import Studies from '@/views/StudiesIndex.vue'
import StudyCreate from '@/views/StudiesCreate.vue'
import LowFrequencyDiseasesWizard from '@/views/LowFrequencyDiseasesWizard.vue'
import Study from '@/views/StudiesView.vue'
import Help from '@/views/Help.vue'
import StatsIndex from '@/views/StatsIndex.vue'
import OrdersToSend from '@/views/Management/OrdersToSend.vue'
import { NAMED_ROUTES, DEFAULT_NAMED_VIEW_BY_ROLE } from '@/utils/constants'

Vue.use(Router)

const checkAuth = (to, from, next) => {
  if (store.getters['auth/isLoggedIn']) {
    const role = store.getters['auth/role']
    next({ name: DEFAULT_NAMED_VIEW_BY_ROLE[role] })
  } else {
    next()
  }
}

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: () => {
        const location = { name: NAMED_ROUTES.LOGIN }
        if (store.getters['auth/isLoggedIn'])
          location.name = DEFAULT_NAMED_VIEW_BY_ROLE[store.getters['auth/role']]
        return location
      },
    },
    {
      path: '/login',
      name: NAMED_ROUTES.LOGIN,
      component: Login,
      meta: { layout: 'login-layout' },
      beforeEnter: checkAuth,
    },
    {
      path: '/login-with-token',
      name: NAMED_ROUTES.LOGIN_WITH_TOKEN,
      component: Login,
      meta: { layout: 'login-layout' },
      beforeEnter: checkAuth,
    },
    {
      path: '/forgot-password',
      name: NAMED_ROUTES.FORGOT_PASSWORD,
      component: ForgotPassword,
      meta: { layout: 'login-layout' },
      beforeEnter: checkAuth,
    },
    {
      path: '/reset-password',
      name: NAMED_ROUTES.RESET_PASSWORD,
      component: ResetPassword,
      meta: { layout: 'login-layout' },
      beforeEnter: checkAuth,
    },
    {
      path: '/register',
      name: NAMED_ROUTES.REGISTER,
      component: Register,
      meta: { layout: 'login-layout' },
      beforeEnter: checkAuth,
    },
    {
      path: '/management',
      redirect: { name: 'users' },
      component: { template: '<router-view />' },
      meta: { auth: { roles: ['lab', 'admin'] } },
      children: [
        {
          path: 'oauth-credentials',
          name: NAMED_ROUTES.OAUTH_CREDENTIALS,
          component: OAuthCredentials,
        },
        {
          path: 'bulk-studies',
          name: NAMED_ROUTES.BULK_STUDIES,
          component: BulkStudiesLoad,
          meta: { auth: { roles: 'admin' } },
        },
        {
          path: 'tumors',
          name: NAMED_ROUTES.TUMORS,
          component: Tumors,
          meta: { auth: { roles: 'admin' } },
        },
        {
          path: 'tumors/create',
          name: NAMED_ROUTES.TUMOR_CREATE,
          component: Tumor,
          meta: { auth: { roles: 'admin' } },
        },
        {
          path: 'tumors/:primaryTumorId',
          name: NAMED_ROUTES.TUMOR,
          component: Tumor,
          meta: { auth: { roles: 'admin' } },
          props: ({ params }) => ({
            primaryTumorId: Number.parseInt(params.primaryTumorId, 10),
          }),
        },
        {
          path: 'biomarkers',
          name: NAMED_ROUTES.BIOMARKERS,
          component: Biomarkers,
          meta: { auth: { roles: 'admin' } },
        },
        {
          path: 'biomarkers/create',
          name: NAMED_ROUTES.BIOMARKER_CREATE,
          component: Biomarker,
          meta: { auth: { roles: 'admin' } },
        },
        {
          path: 'biomarkers/:biomarkerId',
          name: NAMED_ROUTES.BIOMARKER,
          component: Biomarker,
          meta: { auth: { roles: 'admin' } },
          props: ({ params }) => ({
            biomarkerId: Number.parseInt(params.biomarkerId, 10),
          }),
        },
        {
          path: 'countries',
          name: NAMED_ROUTES.COUNTRIES,
          component: Countries,
          meta: { auth: { roles: 'admin' } },
        },
        {
          path: 'countries/create',
          name: NAMED_ROUTES.COUNTRY_CREATE,
          component: Country,
          meta: { auth: { roles: 'admin' } },
        },
        {
          path: 'countries/:countryId',
          name: NAMED_ROUTES.COUNTRY,
          component: Country,
          meta: { auth: { roles: 'admin' } },
          props: ({ params }) => ({
            countryId: Number.parseInt(params.countryId, 10),
          }),
        },
        {
          path: 'labs',
          name: NAMED_ROUTES.LABS,
          component: Labs,
          meta: { auth: { roles: 'admin' } },
        },
        {
          path: 'labs/create',
          name: NAMED_ROUTES.LAB_CREATE,
          component: Lab,
          meta: { auth: { roles: 'admin' } },
        },
        {
          path: 'labs/:labId',
          name: NAMED_ROUTES.LAB,
          component: Lab,
          meta: { auth: { roles: 'admin' } },
          props: ({ params }) => ({
            labId: Number.parseInt(params.labId, 10),
          }),
        },
        {
          path: 'pharmas',
          name: NAMED_ROUTES.PHARMAS,
          component: Pharmas,
          meta: { auth: { roles: 'admin' } },
        },
        {
          path: 'pharmas/create',
          name: NAMED_ROUTES.PHARMA_CREATE,
          component: Pharma,
          meta: { auth: { roles: 'admin' } },
        },
        {
          path: 'pharmas/:pharmaId',
          name: NAMED_ROUTES.PHARMA,
          component: Pharma,
          meta: { auth: { roles: 'admin' } },
          props: ({ params }) => ({
            pharmaId: Number.parseInt(params.pharmaId, 10),
          }),
        },
        {
          path: 'users',
          name: NAMED_ROUTES.USERS,
          component: LoginList,
        },
        {
          path: 'results',
          name: NAMED_ROUTES.RESULTS,
          component: Results,
          meta: { auth: { roles: 'admin' } },
        },
        {
          path: 'panels',
          name: NAMED_ROUTES.PANELS,
          component: Panels,
          meta: { auth: { roles: 'admin' } },
        },
        {
          path: 'panels/panelsCreate',
          name: NAMED_ROUTES.PANELS_CREATE,
          component: PanelCreate,
          meta: { auth: { roles: 'admin' } },
        },
        {
          path: 'panels/:panelId',
          name: NAMED_ROUTES.PANEL,
          component: PanelCreate,
          meta: { auth: { roles: 'admin' } },
          props: ({ params }) => ({
            panelId: Number.parseInt(params.panelId, 10),
          }),
        },
        {
          path: 'genes',
          name: NAMED_ROUTES.GENES,
          component: Genes,
          meta: { auth: { roles: 'admin' } },
        },
        {
          path: 'umbrellas',
          name: NAMED_ROUTES.UMBRELLAS,
          component: Umbrellas,
          meta: { auth: { roles: 'admin' } },
        },
        {
          path: 'umbrellas/umbrellaCreate',
          name: NAMED_ROUTES.UMBRELLA_CREATE,
          component: UmbrellaCreate,
          meta: { auth: { roles: 'admin' } },
        },
        {
          path: 'umbrellas/:umbrellaId',
          name: NAMED_ROUTES.UMBRELLA,
          component: UmbrellaCreate,
          meta: { auth: { roles: 'admin' } },
          props: ({ params }) => ({
            umbrellaId: Number.parseInt(params.umbrellaId, 10),
          }),
        },
        {
          path: 'genes_panels_umbrellas',
          name: NAMED_ROUTES.GENES_PANELS_UMBRELLAS,
          component: GenesPanelsandUmbrellas,
          meta: { auth: { roles: 'admin' } },
        },
        {
          path: 'orders_to_send',
          name: NAMED_ROUTES.ORDERS_TO_SEND,
          component: OrdersToSend,
          meta: { auth: { roles: 'admin' } },
        },
      ],
    },
    {
      path: '/studies',
      name: NAMED_ROUTES.STUDIES,
      component: Studies,
      meta: { auth: { roles: ['lab', 'doctor'] } },
    },
    {
      path: '/study/new',
      name: NAMED_ROUTES.STUDY_CREATE,
      component: StudyCreate,
      meta: { auth: { roles: ['lab', 'doctor'] } },
    },
    {
      path: '/rareDisease/new',
      name: NAMED_ROUTES.RARE_DISEASES,
      component: LowFrequencyDiseasesWizard,
      meta: { auth: { roles: ['lab', 'doctor'] } },
    },
    {
      path: '/study/:id/view',
      name: NAMED_ROUTES.STUDY,
      component: Study,
      meta: { auth: { roles: ['lab', 'doctor'] } },
    },
    {
      path: '/stats',
      name: NAMED_ROUTES.STATS,
      component: StatsIndex,
      meta: { auth: { roles: ['lab', 'doctor'] } },
    },
    {
      path: '/settings',
      name: NAMED_ROUTES.SETTINGS,
      component: Settings,
      meta: { auth: true },
    },
    {
      path: '/help',
      name: NAMED_ROUTES.HELP,
      component: Help,
      meta: { auth: true },
    },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash,
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export default router

export const ROW_STATUS = {
  ENABLED: 'E',
  DISABLED: 'D',
}

export const TUMOR_TYPES = {
  LUNG: 'lung',
  COLON: 'colon',
  BLADDER: 'bladder',
  GASTRIC: 'gastric',
  ESOPHAGUS: 'esophagus',
  GASTROESOPHAGEAL: 'gastroesophageal',
  ENDOMETRIUM: 'Endometrium',
  OVARIO: 'OVARIO',
}

export const NAMED_ROUTES = {
  LOGIN: 'login',
  LOGIN_WITH_TOKEN: 'loginWithToken',
  FORGOT_PASSWORD: 'forgotPassword',
  RESET_PASSWORD: 'resetPassword',
  REGISTER: 'register',

  OAUTH_CREDENTIALS: 'oauthCredentials',
  BULK_STUDIES: 'bulkStudies',
  BIOMARKERS: 'biomarkers',
  BIOMARKER_CREATE: 'biomarkerCreate',
  BIOMARKER: 'biomarker',
  COUNTRIES: 'countries',
  COUNTRY_CREATE: 'countryCreate',
  COUNTRY: 'country',
  LABS: 'labs',
  LAB_CREATE: 'labCreate',
  LAB: 'lab',
  PHARMAS: 'pharmas',
  PHARMA_CREATE: 'pharmasCreate',
  PHARMA: 'pharma',
  USERS: 'users',
  TUMORS: 'tumors',
  TUMOR_CREATE: 'tumorCreate',
  TUMOR: 'tumor',
  RESULTS: 'results',
  PANELS: 'panels',
  PANELS_CREATE: 'panelCreate',
  PANEL: 'panel',
  GENES: 'genes',
  UMBRELLAS: 'umbrellas',
  UMBRELLA: 'umbrella',
  UMBRELLA_CREATE: 'umbrellaCreate',
  GENES_PANELS_UMBRELLAS: 'genesPanelsUmbrellas',
  ORDERS_TO_SEND: 'ordersToSend',

  STUDIES: 'studies',
  STUDY_CREATE: 'studyCreate',
  RARE_DISEASES: 'rareDiseases',
  STUDY: 'study',
  SETTINGS: 'settings',
  HELP: 'help',
  STATS: 'stats',
}

export const DEFAULT_NAMED_VIEW_BY_ROLE = {
  admin: NAMED_ROUTES.USERS,
  lab: NAMED_ROUTES.STUDIES,
  doctor: NAMED_ROUTES.STUDIES,
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group cstom-form-group"},[_c('form-label',{staticClass:"cstom-form-label",attrs:{"required":""}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_vm._l((_vm.tests),function(test){return _c('d-col',{key:test.code,class:{
      'test-picker cstm-col-sm-6-2': true,
      'test-picker-active cstm-col-sm-6-2': _vm.isTestSelected(test),
    }},[_c('div',{staticClass:"p-2 mb-1 mt-1 test-picker-block"},[_c('d-row',{staticClass:"align-items-center"},[_c('d-col',{attrs:{"sm":"12","md":"4"}},[_c('d-form-checkbox',{attrs:{"id":test.code,"value":test.code,"disabled":_vm.checkOnePharmaIsDisabled(test.code, test),"toggle":""},model:{value:(_vm.selectedTests),callback:function ($$v) {_vm.selectedTests=$$v},expression:"selectedTests"}},[(!test.allowedPharmas.find(function (ph) { return ph.diagnosisSetting; }))?_c('h6',{staticClass:"m-0 p-0 d-inline-block-2"},[_vm._v(" "+_vm._s(test.name)+" ")]):(
                test.allowedPharmas.find(function (ph) { return ph.diagnosisSetting; })
              )?_c('h6',{staticClass:"m-0 p-0 d-inline-block"},[_vm._v(" "+_vm._s(_vm.currentlySelectedPharmaCustomName(test))+" ")]):_vm._e()])],1),_c('d-col',{attrs:{"sm":"12","md":"8"}},[(_vm.isTestSelected(test))?_c('d-form-select',{staticClass:"pharma",class:{ 'is-invalid': !_vm.isTestSelected(test).pharmaId },attrs:{"id":"pharmaSelect","value":_vm.isTestSelected(test).pharmaId,"options":_vm.pharmaOptionsFor(test)},on:{"change":function($event){return _vm.setTestPharma({ code: test.code, value: $event })}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Seleccione programa")]),(
                _vm.testPerPharmaUnavailableTests.find(
                  function (opt) { return opt.testCode == test.code; }
                )
              )?_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v(" "+_vm._s(_vm.testPerPharmaUnavailableTests .filter(function (opt) { return opt.testCode == test.code; }) .map(function (val) { return val.pharma.name + '*'; }) .join(', '))+" ")]):_vm._e()]):_vm._e()],1)],1)],1)])}),(!_vm.testsAreValid)?_c('p',{staticClass:"text-danger cstom-form-label"},[_vm._v(" Faltan seleccionar programas para algunos estudios ")]):_vm._e(),(
      _vm.testPerPharmaUnavailableTests.length &&
      _vm.tests.find(function (test) { return _vm.testPerPharmaUnavailableTests.some(function (t) { return t.testCode === test.code; }); }
      ) &&
      !!_vm.testPerPharmaUnavailableTests.find(function (t) { return t.pharma.caps.some(function (cap) { return cap.caps !== 0; }); }
      )
    )?_c('p',{staticClass:"text-accent"},[_vm._v(" El cupo de tests: "+_vm._s(_vm.testPerPharmaUnavailableTests .filter(function (t) { return t.pharma.caps.some(function (cap) { return cap.caps !== 0; }); }) .map(function (val) { return val.testCode.toUpperCase() + "(" + (val.pharma.name) + ")"; }) .join(', '))+" asignado por el/los sponsor/s para este mes han llegado al máximo permitido. Para solicitarlos de forma privada puede enviar mail a: "),_c('a',{staticClass:"text-accent",attrs:{"href":"mailto:presupuesto@biomakers.net"}},[_vm._v(" presupuesto@biomakers.net")]),_vm._v(" junto a la orden médica, para poder acercarle un presupuesto. ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }
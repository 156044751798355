<template>
  <div>
    <span v-if="wizardAesthethic"> Fecha de nacimiento</span>
    <d-input-group class="custom-inline-easy-dp">
      <d-input
        v-model="day"
        type="number"
        placeholder="DD"
        min="1"
        :max="dayCannotBeInTheFuture()"
        :class="{
          'border-danger': $v.day.$invalid && hasError && forceError,
          'border-success': !$v.day.$invalid,
          'wizard-aesthethic': wizardAesthethic,
        }"
        :disabled="disabled"
        @input="updateDate"
        @change="updateDate"
      />
      <d-form-select
        v-model="month"
        :options="monthsAvailable"
        :class="{
          'border-danger': month === '' && hasError && forceError,
          'border-success': month !== '',
          'wizard-aesthethic': wizardAesthethic,
        }"
        :disabled="disabled"
      >
        <option :value="''" disabled>MM</option>
      </d-form-select>
      <d-form-input
        v-model="year"
        type="number"
        placeholder="AAAA"
        min="1900"
        :max="yearCannotBeInTheFuture()"
        :class="{
          'border-danger': $v.year.$invalid && hasError && forceError,
          'border-success': !$v.year.$invalid,
          'wizard-aesthethic': wizardAesthethic,
        }"
        :disabled="disabled"
        @input="updateDate"
        @change="updateDate"
      />
    </d-input-group>
  </div>
</template>

<script>
import { required, minValue, maxValue } from 'vuelidate/lib/validators'
import PlutonBaseMixin from 'src/components/mixins/PlutonBaseMixin'

export default {
  name: 'EasyDatepicker',
  mixins: [PlutonBaseMixin],
  props: {
    disabled: { type: Boolean, required: false, default: false },
    defaultValue: { type: Date, required: false, default: null },
    wizardAesthethic: { type: Boolean, required: false, default: false },
    hasError: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    forceError: {
      type: Boolean,
      default: () => false,
      required: false,
    },
  },
  data() {
    return {
      months: [
        { value: '1', text: 'Enero' },
        { value: '2', text: 'Febrero' },
        { value: '3', text: 'Marzo' },
        { value: '4', text: 'Abril' },
        { value: '5', text: 'Mayo' },
        { value: '6', text: 'Junio' },
        { value: '7', text: 'Julio' },
        { value: '8', text: 'Agosto' },
        { value: '9', text: 'Septiembre' },
        { value: '10', text: 'Octubre' },
        { value: '11', text: 'Noviembre' },
        { value: '12', text: 'Diciembre' },
      ],
      day: '',
      month: '',
      year: '',
      disabledDates: new Date(),
      date: new Date(),
    }
  },
  validations: {
    day: {
      required,
      minValue: minValue(1),
      maxValue: maxValue(31),
    },
    month: { required },
    year: {
      required,
      minValue: minValue(1900),
      maxValue: maxValue(new Date().getFullYear()),
    },
  },
  computed: {
    monthsAvailable() {
      return this.months.filter(
        (m) => m.value <= this.monthCannotBeInTheFuture()
      )
    },
  },
  watch: {
    day: {
      handler(value) {
        if (this.day <= this.dayCannotBeInTheFuture()) {
          this.day = String(value)
        } else {
          this.day = String(this.dayCannotBeInTheFuture())
        }
        this.updateDate()
      },
    },
    month: {
      handler(value) {
        if (this.month < this.monthCannotBeInTheFuture()) {
          this.month = String(value)
        } else if (this.month == this.monthCannotBeInTheFuture()) {
          this.day = this.setCorrectDay(this.day)
        } else {
          this.month = String(this.monthCannotBeInTheFuture())
          this.day = this.setCorrectDay(this.day)
        }
        this.updateDate()
      },
    },
    year: {
      handler(value) {
        if (this.year < this.yearCannotBeInTheFuture()) {
          this.year = String(value)
        } else {
          this.year = String(this.yearCannotBeInTheFuture())
          //   this.year = this.setCorrectYear(this.year)
          this.month = this.setCorrectMonth(this.month)
          if (this.month != '') this.day = this.setCorrectDay(this.day)
        }
        this.updateDate()
      },
    },
  },
  async mounted() {
    await this.setDefaultValues()
  },
  methods: {
    setDefaultValues() {
      if (this.defaultValue) {
        let y = this.defaultValue.getFullYear()
        let d = this.defaultValue.getDate()
        let m = this.defaultValue.getMonth() + 1
        this.year = String(y)
        this.day = String(d)
        this.month = String(m)
      }
    },
    dayCannotBeInTheFuture() {
      if (
        this.year == this.disabledDates.getFullYear() &&
        this.month == this.disabledDates.getMonth() + 1
      )
        return this.disabledDates.getDate()
      return 31
    },
    monthCannotBeInTheFuture() {
      if (this.year == this.disabledDates.getFullYear()) {
        return this.disabledDates.getMonth() + 1
      }
      return 12
    },
    yearCannotBeInTheFuture() {
      return this.disabledDates.getFullYear()
    },
    setCorrectDay(val) {
      if (val <= this.dayCannotBeInTheFuture()) {
        return String(val)
      }
    },
    setCorrectMonth(val) {
      if (val <= this.monthCannotBeInTheFuture()) {
        return String(val)
      }
    },
    setCorrectYear(val) {
      if (parseInt(val) < 1900) {
        return 1900
      } else if (val > this.disabledDates.getFullYear())
        return this.disabledDates.getFullYear()
      return val
    },
    updateDate() {
      const monthStr = String(this.month).padStart(2, '0')
      const dayStr = String(this.day).padStart(2, '0')
      const yearStr = String(this.year)

      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.date = new Date(`${monthStr}/${dayStr}/${yearStr}`)
        this.$emit('input', this.date)
      } else {
        this.$emit('invalid-input', '')
      }
    },
  },
}
</script>
<style lang="scss">
.wizard-aesthethic {
  background-color: var(--gray1);
  color: var(--gray1);
}
.custom-inline-easy-dp {
  width: 100%;
}
</style>

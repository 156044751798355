<template>
  <div class="form-group cstom-form-group">
    <form-label required class="cstom-form-label">
      {{ label }}
    </form-label>
    <d-col
      v-for="test in tests"
      :key="test.code"
      :class="{
        'test-picker cstm-col-sm-6-2': true,
        'test-picker-active cstm-col-sm-6-2': isTestSelected(test),
      }"
    >
      <div class="p-2 mb-1 mt-1 test-picker-block">
        <d-row class="align-items-center">
          <d-col sm="12" md="4">
            <d-form-checkbox
              :id="test.code"
              v-model="selectedTests"
              :value="test.code"
              :disabled="checkOnePharmaIsDisabled(test.code, test)"
              toggle
            >
              <h6
                v-if="!test.allowedPharmas.find((ph) => ph.diagnosisSetting)"
                class="m-0 p-0 d-inline-block-2"
              >
                {{ test.name }}
              </h6>
              <h6
                v-else-if="
                  test.allowedPharmas.find((ph) => ph.diagnosisSetting)
                "
                class="m-0 p-0 d-inline-block"
              >
                {{ currentlySelectedPharmaCustomName(test) }}
              </h6>
            </d-form-checkbox>
          </d-col>
          <d-col sm="12" md="8">
            <d-form-select
              v-if="isTestSelected(test)"
              id="pharmaSelect"
              :value="isTestSelected(test).pharmaId"
              class="pharma"
              :options="pharmaOptionsFor(test)"
              :class="{ 'is-invalid': !isTestSelected(test).pharmaId }"
              @change="setTestPharma({ code: test.code, value: $event })"
            >
              <option :value="null" disabled>Seleccione programa</option>
              <option
                v-if="
                  testPerPharmaUnavailableTests.find(
                    (opt) => opt.testCode == test.code
                  )
                "
                :value="null"
                disabled
              >
                {{
                  testPerPharmaUnavailableTests
                    .filter((opt) => opt.testCode == test.code)
                    .map((val) => val.pharma.name + '*')
                    .join(', ')
                }}
              </option>
            </d-form-select>
          </d-col>
        </d-row>
      </div>
    </d-col>
    <p v-if="!testsAreValid" class="text-danger cstom-form-label">
      Faltan seleccionar programas para algunos estudios
    </p>
    <p
      v-if="
        testPerPharmaUnavailableTests.length &&
        tests.find((test) =>
          testPerPharmaUnavailableTests.some((t) => t.testCode === test.code)
        ) &&
        !!testPerPharmaUnavailableTests.find((t) =>
          t.pharma.caps.some((cap) => cap.caps !== 0)
        )
      "
      class="text-accent"
    >
      El cupo de tests:
      {{
        testPerPharmaUnavailableTests
          .filter((t) => t.pharma.caps.some((cap) => cap.caps !== 0))
          .map((val) => val.testCode.toUpperCase() + `(${val.pharma.name})`)
          .join(', ')
      }}
      asignado por el/los sponsor/s para este mes han llegado al máximo
      permitido. Para solicitarlos de forma privada puede enviar mail a:
      <a class="text-accent" href="mailto:presupuesto@biomakers.net">
        presupuesto@biomakers.net</a
      >
      junto a la orden médica, para poder acercarle un presupuesto.
    </p>
  </div>
</template>

<script>
import FormLabel from './Label'
import { shuffle } from '../../utils'
import axios from 'axios'

export default {
  name: 'WizardTestPicker',
  components: {
    FormLabel,
  },
  props: {
    tests: {
      type: Array,
      default: () => [],
    },

    unavailableTests: {
      type: Array,
      default: () => [],
    },
    // primaryTumorId: {
    //   type: Number,
    //   default: () => null,
    //   required: true,
    // },
    label: {
      type: String,
      default: () => 'Estudios a realizar',
      required: false,
    },
    loadedTests: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      selectedTests: [], // this exists only to leverage vue's checkbox models
      selectedTestsWithPharmas: [],
      recentBiomakers: [],
      testPerPharmaUnavailableTests: [],
    }
  },
  computed: {
    testsAreValid() {
      return this.selectedTestsWithPharmas.every((item) => item.pharmaId)
    },
  },
  watch: {
    selectedTests: {
      handler: function (newTests) {
        const filteredData = newTests.map((testCode) => {
          const existingItem = this.isTestSelected({ code: testCode })
          const biomarker = this.tests.find(
            (biomarker) => biomarker.code === testCode
          )

          if (!existingItem)
            return {
              testCode,
              testId: biomarker.id,
              pharmaId: null,
              requiresValidationWithNewApi: null,
            }
          return {
            ...existingItem,
            testId: biomarker.id,
            requiresValidationWithNewApi: biomarker.allowedPharmas.find(
              (ph) => ph.id == existingItem.pharmaId
            )
              ? biomarker.allowedPharmas.find(
                  (ph) => ph.id == existingItem.pharmaId
                ).validateEnvelopeWithNewApi
              : null,
          }
        })
        this.selectedTestsWithPharmas = [...filteredData]
      },
      deep: true,
    },
    selectedTestsWithPharmas: {
      handler: function (value) {
        let processedValues = []

        //tengo igual numero de test, solo selecciono farma
        value.forEach(({ testId, testCode, pharmaId }) => {
          if (pharmaId !== null) {
            processedValues.push({
              testId: testId,
              testCode: testCode,
              pharmaId: pharmaId,
              requiresValidationWithNewApi: this.tests
                .find((test) => test.code == testCode)
                .allowedPharmas.find((ph) => ph.id == pharmaId)
                .requiresValidationWithNewApi,
              validateEnvelopeWithNewApi: this.tests
                .find((test) => test.code == testCode)
                .allowedPharmas.find((ph) => ph.id == pharmaId)
                .validateEnvelopeWithNewApi,
            })
          } else {
            processedValues.push({
              testId: testId,
              testCode: testCode,
              pharmaId: pharmaId,
              requiresValidationWithNewApi: null,
              validateEnvelopeWithNewApi: null,
            })
          }
        })
        this.$emit('tests-changed', processedValues)
      },
      deep: true,
    },
    tests: {
      handler(newValue) {
        this.setUpTests(newValue)
        this.unselectSpecificTest('ros1')
      },
    },
  },
  mounted() {
    if (this.loadedTests.length > 0) {
      this.selectedTests = this.loadedTests.map((t) => t.testCode)
      this.selectedTestsWithPharmas = this.loadedTests
    } else {
      this.setUpTests(this.tests)
    }
  },

  async created() {
    const result = await axios
      .get(`/api2/biomarker/recent`)
      .then((response) => response.data)
    result.forEach((elem) => this.recentBiomakers.push(elem.code))
  },

  methods: {
    checkOnePharmaIsDisabled(testCode, test) {
      try {
        if (
          this.pharmaOptionsFor(test).length == 0 &&
          this.testPerPharmaUnavailableTests.some((t) => t.testCode == testCode)
        ) {
          this.unselectSpecificTest(testCode)
          return true
        }
      } catch (error) {
        return false
      }
    },
    isDisabledThisTest(code) {
      let thisTestHasCaps = this.unavailableTests.find((test) =>
        test.caps.length > 0 &&
        test.code == code &&
        test.allowedPharmas.find(
          (pharma) => pharma.caps && pharma.id == this.isTestSelected({ code })
        ) != undefined
          ? test.allowedPharmas.find(
              (pharma) =>
                pharma.caps &&
                pharma.id == this.isTestSelected({ code }).pharmaId
            )
          : null
      )
      if (!thisTestHasCaps) return null
      if (
        !this.testPerPharmaUnavailableTests.find(
          (test) => test.code == thisTestHasCaps.code
        )
      ) {
        this.testPerPharmaUnavailableTests.push(thisTestHasCaps)
        this.disabledPharmaOptionsForThisTest(code)
      }
      return thisTestHasCaps.allowedPharmas
        .find((ph) => ph.caps)
        .caps.some((cap) => cap.numberOfAvailableTests <= 0)
    },
    disabledPharmaOptionsForThisTest(code) {
      if (!this.isDisabledThisTest(code)) return null
      return this.testPerPharmaUnavailableTests
        .map((val) => val.allowedPharmas)[0]
        .filter(
          (pharmas) =>
            //eslint-disable-next-line no-prototype-builtins
            pharmas.hasOwnProperty('caps') &&
            pharmas.caps.some((cap) => cap.numberOfAvailableTests <= 0)
        )
        .map((pharma) => ({
          ...pharma,
          value: pharma.id,
          text: pharma.name,
        }))
    },
    setUpTests(tests) {
      this.selectedTests = []
      this.selectedTestsWithPharmas = []
      if (
        tests.every((biomarker) => biomarker.allowedPharmas.length === 1) &&
        new Set(tests.map((biomarker) => biomarker.allowedPharmas[0].id)).size >
          1
      ) {
        tests.forEach(({ id, code, pharmaId }) => {
          this.selectedTestsWithPharmas.push({
            testId: id,
            testCode: code,
            pharmaId: pharmaId,
          })
          this.selectedTests.push(code)
        })
        return
      }
      tests.forEach(({ id, code }) => {
        this.selectedTests.push(code)
        this.selectedTestsWithPharmas.push({
          testId: id,
          testCode: code,
          pharmaId: null,
        })
      })
    },
    unselectSpecificTest(testCode) {
      let idx = this.selectedTests.findIndex((code) => code === testCode)
      let idxPharma = this.selectedTestsWithPharmas.findIndex(
        (test) => test.code === testCode
      )
      if (idx !== -1) {
        this.selectedTests.splice(idx, 1)
      } else if (idxPharma !== -1) {
        this.selectedTestsWithPharmas.splice(idxPharma, 1)
      }
    },
    isRecentBio(testCode) {
      return JSON.parse(JSON.stringify(this.recentBiomakers)).includes(testCode)
    },

    pharmaOptionsFor({ code, allowedPharmas }) {
      this.isDisabledThisTest(code)
      let processAllowedPharmas = allowedPharmas.filter(
        (ph) => ph.diagnosisSetting
      ).length
        ? allowedPharmas.filter((ph) => ph.diagnosisSetting)
        : allowedPharmas
      let pharmasWithoutCaps = []
      for (let index = 0; index < this.unavailableTests.length; index++) {
        const element = this.unavailableTests[index]
        if (element.caps) {
          for (let idx = 0; idx < element.allowedPharmas.length; idx++) {
            const element2 = element.allowedPharmas[idx]
            if (
              element2.caps &&
              element2.caps.some((cap) => cap.numberOfAvailableTests <= 0) &&
              element.code === code
              // &&
              // element2.customSettings.find(
              //   (cs) => cs.primaryTumorId === this.primaryTumorId
              // )
            ) {
              if (element2.caps.length > 1) {
                let userCap = element2.caps.find((c) => c.userId != null)
                if (userCap) element2.caps = [userCap]
              }
              let objectToPush =
                element2.caps.find((cap) => cap.numberOfAvailableTests <= 0) !=
                undefined
                  ? { testCode: element.code, pharma: element2 }
                  : null
              if (!pharmasWithoutCaps.includes(element2)) {
                pharmasWithoutCaps.push(element2)
              }
              if (
                objectToPush != null &&
                !this.testPerPharmaUnavailableTests.find(
                  (t) => JSON.stringify(t) == JSON.stringify(objectToPush)
                )
              ) {
                this.testPerPharmaUnavailableTests.push(objectToPush)
              }
            }
          }
        }
      }

      let pharmas = processAllowedPharmas
        .filter((ph) => !pharmasWithoutCaps.some((p) => p.code == ph.code))
        .map((pharma) => ({
          ...pharma,
          value: pharma.id,
          text: pharma.name,
        }))
      if (pharmas.length === 1) {
        this.isTestSelected({ code }).pharmaId = pharmas[0].id
        this.isTestSelected({ code }).requiresValidationWithNewApi = this.tests
          .find((test) => test.code == code)
          .allowedPharmas.find(
            (ph) => ph.id == pharmas[0].id
          ).validateEnvelopeWithNewApi
      }
      if (pharmas.length > 1) pharmas = shuffle(pharmas)
      return pharmas
    },
    currentlySelectedPharmaCustomName(test) {
      let actualTest = this.selectedTestsWithPharmas.find(
        (t) => t.testCode === test.code
      )
        ? this.selectedTestsWithPharmas.find((t) => t.testCode === test.code)
        : null
      let name =
        actualTest && actualTest.pharmaId != undefined
          ? test.allowedPharmas.find(
              (ph) =>
                ph.diagnosisSetting &&
                ph.diagnosisSetting.pharmaId == actualTest.pharmaId
            ).diagnosisSetting.customName
          : test.name
      return name
    },
    isTestSelected({ code }) {
      return this.selectedTestsWithPharmas.find(
        (item) => item.testCode === code
      )
    },
    setTestPharma({ code, value }) {
      const idx = this.selectedTestsWithPharmas.findIndex(
        (test) => test.testCode === code
      )
      if (this.selectedTestsWithPharmas[idx].pharmaId !== value)
        delete this.selectedTestsWithPharmas[idx].customEnvelopeCode
      const newPharmas = [...this.selectedTestsWithPharmas]
      newPharmas.splice(idx, 1, {
        ...newPharmas[idx],
        pharmaId: value,
      })
      this.selectedTestsWithPharmas = newPharmas
    },
  },
}
</script>

<style lang="scss">
custom-active {
  background-color: #f9f9ff;
  border: 1px solid #afa8f3;
}
.test-picker .test-picker-block {
  border: 1px solid #efedfd;
  border-radius: 4px;
  box-sizing: border-box;
}

.test-picker-active .test-picker-block {
  background-color: #f9f9ff;
  border: 1px solid #afa8f3;
}

.test-picker-active
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border: 1px solid #fff;
}
@media (min-width: 1200px) {
  .cstom-form-group {
    width: 180%;
  }
  .cstm-col-sm-6-2 {
    max-width: 60%;
    width: 60%;
  }
  .d-inline-block-2 {
    position: absolute;
    display: flex;
    align-items: center;
    top: -1vh;
    left: 50%;

    padding-bottom: 2rem;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .cstom-form-group {
    width: 160%;
  }
  .cstm-col-sm-6-2 {
    max-width: 160%;
  }
  .d-inline-block-2 {
    position: absolute;
    top: 3%;
    padding-bottom: 30%;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .cstom-form-group {
    width: 100%;
  }
  .cstm-col-sm-6-2 {
    max-width: 200%;
    width: 200%;
  }
  .d-inline-block-2 {
    position: absolute;
    top: 3%;
    padding-bottom: 30%;
  }
}

@media (max-width: 767px) {
  .cstom-form-group {
    width: 75%;
  }
  .cstm-col-sm-6-2 {
    max-width: 150%;
    width: 150%;
  }
  .d-inline-block-2 {
    position: absolute;
    top: 3%;
    padding-bottom: 30%;
  }
}

.cstom-form-label {
  position: relative;
  left: 2%;
}
.cstom-new-label {
  position: relative;
  left: 65%;
}
</style>

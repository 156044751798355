<template>
  <div class="py-0">
    <div class="d-none d-md-block text-center">
      <d-button class="btn-accent" @click="handleClearFilters(true)">
        Borrar filtros
      </d-button>
    </div>
    <div class="form-group">
      <label>Paciente</label>
      <d-form-input
        id="Patient-label"
        v-model="searchValue"
        name="Patient"
        :value="patient.name"
        type="text"
        placeholder="Nombre y Apellido"
        @input="throttledSearch('name', $event)"
      />
      <div v-if="checkForSearching == true">Buscando...</div>
    </div>
    <div class="form-group">
      <label>Sexo</label>
      <d-form-select
        name="gender"
        :value="patient.gender"
        :options="genderOptions"
        class="text-truncate"
        @change="updatePatientFilters({ field: 'gender', value: $event })"
      />
    </div>
    <div class="form-group">
      <label>Tumor primario</label>
      <d-form-select
        :value="study.primaryTumor"
        :options="primaryTumorOptions"
        class="text-truncate"
        @change="
          (event) =>
            setPrimaryTumor({
              primaryTumorId: event,
              primaryTumorCode: currentPrimaryTumorCode,
            })
        "
      />
    </div>
    <div
      v-if="currentPrimaryTumorCode === TUMOR_TYPES.COLON"
      class="form-group"
    >
      <label for="location">Localización del tumor</label>
      <d-form-select
        id="location"
        class="text-truncate"
        :value="patient.diagnosisLocation"
        :options="diagnosisLocationOptions"
        @change="
          updatePatientFilters({ field: 'diagnosisLocation', value: $event })
        "
      />
    </div>
    <div
      v-if="currentPrimaryTumorCode === TUMOR_TYPES.BLADDER"
      class="form-group"
    >
      <label for="previousTherapies">Terapias previas</label>
      <d-form-select
        id="previousTherapies"
        class="text-truncate"
        :value="patient.previousTherapies"
        :options="previousTherapiesOptions"
        @change="
          updatePatientFilters({ field: 'previousTherapies', value: $event })
        "
      />
    </div>
    <div
      v-if="
        [TUMOR_TYPES.LUNG, TUMOR_TYPES.BLADDER].includes(
          currentPrimaryTumorCode
        )
      "
      class="form-group"
    >
      <label for="smoker_status">Condición fumador</label>
      <d-form-select
        id="smoker_status"
        class="text-truncate"
        :value="patient.smokerStatus"
        :options="smokerStatusOptions"
        @change="updatePatientFilters({ field: 'smokerStatus', value: $event })"
      />
    </div>
    <div
      v-if="
        [
          TUMOR_TYPES.LUNG,
          TUMOR_TYPES.GASTRIC,
          TUMOR_TYPES.ESOPHAGUS,
          TUMOR_TYPES.GASTROESOPHAGEAL,
        ].includes(currentPrimaryTumorCode)
      "
      class="form-group"
    >
      <label for="diagnosis">Diagnóstico</label>
      <d-form-select
        id="diagnosis"
        class="text-truncate"
        :value="patient.diagnosis"
        :options="diagnosisOptions"
        @change="updatePatientFilters({ field: 'diagnosis', value: $event })"
      />
    </div>
    <div class="form-group">
      <label>Tipo de muestra</label>
      <d-form-select
        :value="study.sampleType"
        :options="sampleTypeOptions"
        class="text-truncate"
        @change="updateStudyFilters({ field: 'sampleType', value: $event })"
      />
    </div>
    <div class="form-group">
      <div
        v-d-toggle.patient-diagnosis-stage-filter
        class="d-flex justify-content-between checkbox-label"
      >
        <label for="diagnosisStage">Estadío de la enfermedad</label>
        <span class="material-icons text-dark"> expand_more </span>
      </div>
      <d-collapse id="patient-diagnosis-stage-filter" class="checkbox-filter">
        <!-- eslint-disable -->
        <d-checkbox
          :checked="!this.patient.diagnosisStage.length"
          :disabled="!this.patient.diagnosisStage.length"
          @change="updatePatientFilters({ field: 'diagnosisStage', value: [] })"
          >Todos los estados</d-checkbox
        >
        <d-checkbox
          v-model="diagnosisStage"
          id="diagnosisStage"
          value="explicit_null"
          >No informa</d-checkbox
        >
        <d-checkbox
          v-for="[key, value] in Object.entries(
            patientLabels.diagnosisStage || {}
          )"
          :key="key"
          v-model="diagnosisStage"
          name="diagnosisStage"
          :value="key"
          >{{ value }}</d-checkbox
        >
        <!-- eslint-enable -->
      </d-collapse>
    </div>
    <div class="form-group">
      <div
        v-d-toggle.study-test-code-filter
        class="d-flex justify-content-between checkbox-label"
      >
        <label for="studyTestCode">Biomarcador</label>
        <span class="material-icons text-dark"> expand_more </span>
      </div>
      <d-collapse id="study-test-code-filter" class="checkbox-filter">
        <!-- eslint-disable -->
        <d-checkbox
          :checked="!studyTestCode.length"
          :disabled="!studyTestCode.length"
          @change="updateStudyTestFilters({ field: 'biomarkerId', value: [] })"
          >Todos los biomarcadores</d-checkbox
        >
        <d-checkbox
          v-for="[key, value] in Object.entries(
            filteredOptions(studyTestLabels, 'biomarkers')
          )"
          :key="key"
          v-model="studyTestCode"
          id="studyTestCode"
          :value="value.index"
          >{{ value.value }}</d-checkbox
        >
        <!-- eslint-enable -->
      </d-collapse>
    </div>
    <div class="form-group">
      <div
        v-d-toggle.study-test-panel-filter
        class="d-flex justify-content-between checkbox-label"
      >
        <label for="studyTestPanels">Paneles</label>
        <span class="material-icons text-dark"> expand_more </span>
      </div>
      <d-collapse id="study-test-panel-filter" class="checkbox-filter">
        <!-- eslint-disable -->
        <d-checkbox
          :checked="!studyPanels.length"
          :disabled="!studyPanels.length"
          @change="updateStudyFilters({ field: 'umbrella', value: [] })"
          >Todos los paneles</d-checkbox
        >
        <d-checkbox
          v-for="[value, key] in Object.entries(studyLabels.umbrellas || {})"
          :key="key"
          v-model="studyPanels"
          id="studyTestPanels"
          :value="value"
          >{{ key }}</d-checkbox
        >
        <!-- eslint-enable -->
      </d-collapse>
    </div>
    <div class="form-group">
      <div
        v-d-toggle.study-test-result-filter
        class="d-flex justify-content-between checkbox-label"
      >
        <label for="studyTestResult">Resultado</label>
        <span class="material-icons text-dark"> expand_more </span>
      </div>
      <d-collapse id="study-test-result-filter" class="checkbox-filter">
        <!-- eslint-disable -->
        <d-checkbox
          :checked="!(studyTestResult && studyTestResult.length)"
          :disabled="!(studyTestResult && studyTestResult.length)"
          @change="updateStudyTestFilters({ field: 'result', value: [] })"
          >Todos los resultados</d-checkbox
        >
        <d-checkbox v-model="studyTestResult" id="studyTestResult" :value="null"
          >No informa</d-checkbox
        >
        <d-checkbox
          v-for="[key, value] in Object.entries(
            resultsFilteredOptions(studyTestLabels, 'result')
          )"
          :key="key"
          v-model="studyTestResult"
          name="studyTestResult"
          :value="value.value"
          >{{ value.index }}
        </d-checkbox>
        <!-- eslint-enable -->
      </d-collapse>
    </div>
    <div class="form-group">
      <div
        v-d-toggle.study-status-filter
        class="d-flex justify-content-between checkbox-label"
      >
        <label for="studyStatus">Estado</label>
        <span class="material-icons text-dark"> expand_more </span>
      </div>
      <d-collapse id="study-status-filter" class="checkbox-filter">
        <!-- eslint-disable -->
        <d-checkbox
          :checked="!studyStatus.length"
          :disabled="!studyStatus.length"
          @change="updateStudyFilters({ field: 'status', value: [] })"
          >Todos los estados</d-checkbox
        >
        <d-checkbox
          v-for="[key, value] in Object.entries(studyLabels.status || {})"
          :key="key"
          v-model="studyStatus"
          id="studyStatus"
          :value="key"
          >{{ value }}</d-checkbox
        >
        <!-- eslint-enable -->
      </d-collapse>
    </div>
    <div class="form-group">
      <label>Fecha de solicitud</label>
      <d-row>
        <d-col cols="6" class="pr-1 created-at-from">
          <d-datepicker
            typeable
            :value="study.createdAtFrom"
            placeholder="Desde (YYYY-MM-DD)"
            :language="calendarLang"
            format="yyyy-MM-dd"
            :disabled-dates="disabledDates"
            :use-utc="true"
            @selected="
              updateStudyFilters({
                field: 'createdAtFrom',
                value: ($event && $event.toISOString().split('T')[0]) || null,
              })
            "
          />
        </d-col>
        <d-col cols="6" class="pl-1 created-at-to">
          <d-datepicker
            typeable
            :value="study.createdAtTo"
            :language="calendarLang"
            placeholder="Hasta (YYYY-MM-DD)"
            format="yyyy-MM-dd"
            :disabled-dates="disabledDates"
            :use-utc="true"
            @selected="
              updateStudyFilters({
                field: 'createdAtTo',
                value: ($event && $event.toISOString().split('T')[0]) || null,
              })
            "
          />
        </d-col>
      </d-row>
    </div>

    <div class="form-group">
      <label>Edad del paciente</label>
      <d-row>
        <d-col cols="6" class="pr-1">
          <d-form-input
            v-model="desdeValue"
            :value="patient.ageRangeStart"
            min="1"
            type="number"
            placeholder="Desde"
            @input="throttledSearch('ageRangeStart', $event)"
          />
        </d-col>
        <d-col cols="6" class="pl-1">
          <d-form-input
            v-model="hastaValue"
            :value="patient.ageRangeEnd"
            type="number"
            placeholder="Hasta"
            @input="throttledSearch('ageRangeEnd', $event)"
          />
        </d-col>
      </d-row>
    </div>
    <d-row class="d-flex d-md-none">
      <d-col cols="6">
        <d-button
          block-level
          class="btn-accent"
          @click="() => $eventHub.$emit('toggle-study-filters-sidebar')"
        >
          Filtrar
        </d-button>
      </d-col>
      <d-col cols="6">
        <d-button
          block-level
          theme="secondary"
          @click="handleClearFilters(true)"
        >
          Borrar Filtros
        </d-button>
      </d-col>
    </d-row>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
import { es } from 'vuejs-datepicker/dist/locale'

import { getOptionsFor } from '@/utils'
import { TUMOR_TYPES } from '@/utils/constants'

export default {
  emits: ['clearingFilters'],
  data() {
    return {
      TUMOR_TYPES,
      disabledDates: {
        from: new Date(),
      },
      calendarLang: es,
      patientAgeOptions: {
        range: {
          min: 0,
          max: 140,
        },
        format: {
          to: function (value) {
            return value.toFixed(0)
          },
          from: function (value) {
            return Number(value)
          },
        },
        pips: { mode: 'range', stepped: true, density: 10 },
      },
      checkForSearching: false,
      searchValue: '',
      searchValue2: '',
      desdeValue: '',
      hastaValue: '',
    }
  },
  computed: {
    ...mapGetters('primaryTumors', ['primaryTumors']),
    ...mapGetters('countries', ['sampleTypes']),
    ...mapState('filters', ['initialized', 'study', 'patient', 'studyTest']),
    ...mapState('labelMappings', [
      'studyLabels',
      'patientLabels',
      'studyTestLabels',
    ]),
    diagnosisStage: {
      get() {
        return this.patient.diagnosisStage
      },
      set(value) {
        if (this.patient.diagnosisStage !== value)
          this.updatePatientFilters({ field: 'diagnosisStage', value: value })
      },
    },
    studyStatus: {
      get() {
        return this.study.status
      },
      set(value) {
        if (this.study.status !== value)
          this.updateStudyFilters({ field: 'status', value: value })
      },
    },
    studyTestCode: {
      get() {
        return this.studyTest.biomarkerId
      },
      set(value) {
        if (this.studyTest.biomarkerId !== value)
          this.updateStudyTestFilters({
            field: 'biomarkerId',
            value: value,
          })
      },
    },
    studyTestResult: {
      get() {
        return (this.studyTest && this.studyTest.result) || []
      },
      set(value) {
        if (this.studyTest.result !== value)
          this.updateStudyTestFilters({ field: 'result', value: value })
      },
    },
    studyPanels: {
      get() {
        return this.study.umbrella
      },
      set(val) {
        if (this.study.umbrella !== val) {
          this.updateStudyFilters({ field: 'umbrella', value: val })
        }
      },
    },
    genderOptions() {
      return [
        { value: null, text: 'Ambos sexos' },
        ...getOptionsFor(this.patientLabels.gender),
      ]
    },
    primaryTumorOptions() {
      return [
        { value: null, text: 'Todos los tipos de tumor' },
        ...this.primaryTumors.map((tumor) => ({
          value: tumor.id,
          text: tumor.name,
        })),
      ]
    },
    sampleTypeOptions() {
      return [
        { value: null, text: 'Todos los tipos de muestra' },
        ...this.sampleTypes.map((sampleType) => ({
          value: sampleType.id,
          text: sampleType.label,
        })),
      ]
    },
    currentPrimaryTumorCode() {
      const tumor = this.primaryTumors.find(
        (tumor) => tumor.id === this.study.primaryTumor
      )
      if (tumor) return tumor.code
      return null
    },
    diagnosisLocationOptions() {
      return [
        { value: null, text: 'Todas las ubicaciones de diagnóstico' },
        { value: 'explicit_null', text: 'No informa' },
        ...getOptionsFor(this.patientLabels.diagnosisLocation),
      ]
    },
    smokerStatusOptions() {
      return [
        { value: null, text: 'Todas las condiciones' },
        ...getOptionsFor(this.patientLabels.smokerStatus),
      ]
    },
    previousTherapiesOptions() {
      return [
        { value: null, text: 'Todas las terapias' },
        ...getOptionsFor(this.patientLabels.previousTherapies),
      ]
    },
    diagnosisOptions() {
      return [
        { value: null, text: 'Todos los diagnósticos' },
        ...getOptionsFor(this.patientLabels.diagnosis),
      ]
    },
    filteredOptions() {
      return (data, key) => {
        let aux = []
        if (this.study.primaryTumor) {
          // return (data[key] && data[key][this.study.primaryTumor]) || {}
          // eslint-disable-next-line no-unused-vars
          Object.entries(data[key][this.study.primaryTumor]).forEach(
            ([innerKey, innerValue]) => {
              let objectToPush = { index: innerKey, value: innerValue }
              if (!aux.includes(objectToPush)) {
                aux.push(objectToPush)
              }
            }
          )
        } else {
          let filteredData = {}
          if (!(key in data)) return filteredData
          // eslint-disable-next-line no-unused-vars
          Object.entries(data[key]).forEach(([key2, value]) => {
            Object.entries(value).forEach(([innerKey, innerValue]) => {
              let objectToPush = { index: innerKey, value: innerValue }
              if (!aux.includes(objectToPush)) {
                aux.push(objectToPush)
              }
            })
          })
          aux.forEach(() => {})
        }
        let uniqueValues = [
          ...new Set(aux.map((value) => JSON.stringify(value))),
        ].map((value) => JSON.parse(value))
        return uniqueValues
      }
    },
    resultsFilteredOptions() {
      return (data, key) => {
        let aux = []
        let filteredData = {}
        if (!(key in data)) return filteredData
        // eslint-disable-next-line no-unused-vars
        Object.entries(data[key]).forEach(([key2, value]) => {
          //i.e: ignored-for-stadistics
          // eslint-disable-next-line no-unused-vars
          Object.entries(value).forEach(([innerKey, innerValue]) => {
            // i.e: rejected --> Array of objects
            // eslint-disable-next-line no-unused-vars
            var objectToPush = {
              index: Object.values(innerValue)[0][0].name,
              value: [],
            }
            // eslint-disable-next-line no-unused-vars
            Object.entries(innerValue).forEach(([nestedKey, nestedValue]) => {
              nestedValue.forEach((val) => objectToPush.value.push(val.id))
            })
            if (!aux.includes(objectToPush)) {
              aux.push(objectToPush)
            }
          })
        })
        aux.forEach(() => {})
        return aux
      }
    },
  },
  watch: {
    'patient.name'(val) {
      this.searchValue = val
    },
    'patient.ageRangeStart'(val) {
      this.desdeValue = val
    },
    'patient.ageRangeEnd'(val) {
      this.hastaValue = val
    },
  },
  async mounted() {
    this.disabledDates.from.setDate(this.disabledDates.from.getDate() + 1)
    if (!this.initialized)
      this.initializeFilters(this.$auth.user().relationType)
    if (
      this.$auth
        .user()
        .roles.find((roleType) => roleType.roleType === 'navigator')
    ) {
      this.updateStudyFilters({ field: 'pharma', value: 'FORTREA' })
    }
    this.searchValue = this.$store.state.filters.patient.name
    this.desdeValue = this.$store.state.filters.patient.ageRangeStart
    this.hastaValue = this.$store.state.filters.patient.ageRangeEnd
  },
  methods: {
    ...mapActions('filters', ['setPrimaryTumor']),
    ...mapMutations('filters', [
      'updateStudyFilters',
      'updatePatientFilters',
      'updateStudyTestFilters',
      'initializeFilters',
      'clearFilters',
    ]),
    throttledSearch(field, value) {
      if (this.throttleTimeout) clearTimeout(this.throttleTimeout)
      this.throttleTimeout = setTimeout(() => {
        this.updatePatientFilters({ field: field, value: value })
        this.checkForSearching = false
      }, 700)
      this.checkForSearching = true
    },
    handleCheckboxChange(baseModel, updateFn, key, field) {
      if (key === 'all') this[baseModel] = []
      else if (this[baseModel].length === 1 && this[baseModel].includes(key))
        this[`${baseModel}All`] = ['all']
      else this[`${baseModel}All`] = []
      this.$nextTick(() => {
        this[updateFn]({ field: field, value: this[baseModel] })
      })
    },
    handleClearFilters(toggleMobile = false) {
      let isNavigator = this.$auth
        .user()
        .roles.find((roleType) => roleType.roleType === 'navigator')
      if (isNavigator) {
        this.clearFilters({
          userRole: this.$auth.user().relationType,
          secondaryRole: 'navigator',
        })
        return
      }
      this.clearFilters({
        userRole: this.$auth.user().relationType,
        secondaryRole: null,
      })
      if (toggleMobile) this.$eventHub.$emit('toggle-study-filters-sidebar')
    },
  },
}
</script>

<style scoped>
.text-truncate {
  display: block;
}

label,
.patient-age {
  color: #666;
}

.patient-age {
  font-size: 0.8rem;
}

.checkbox-label label {
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkbox-filter {
  white-space: break-spaces;
  font-size: 0.8rem;
}

.checkbox-filter .custom-control {
  padding-left: 1.2rem;
}

.noUi-target {
  margin: 10px 0 35px 0;
}
</style>

<template>
  <div class="mx-4 p-4 border-custom position-relative h-80 w-80 principalDiv">
    <div class="mx-2 p-2 border-custom position-relative h-20 w-20">
      <div class="tabs-container">
        <d-btn
          v-for="(tab, index) in labelItems"
          :key="tab.text"
          :class="{
            'btn general-bg text-purple c-btn': step === index + 1,
            'btn general-bg text-secondary c-btn': step !== index + 1,
          }"
          disabled
          ><span
            :class="{
              'custom-number': step === index + 1,
              'custom-number-disabled': step !== index + 1,
            }"
            >{{ index + 1 }}</span
          >{{ tab.title }}</d-btn
        >
      </div>
    </div>
    <hr />
    <div class="align-items-center">
      <h4 class="text-center">
        Estudio genético para la detección de los genes SMN1 y SMN2 para AME
      </h4>

      <d-row v-if="step === 1" class="c-mb-8 to-left" fluid>
        <hr />

        <d-col class="align-items-center">
          <d-row class="justify-content-start">
            <d-col class="col-sm-12">
              <p style="color: var(--grey-3)">
                Por favor complete los datos del paciente
              </p>
              <v-select
                name="sampleType"
                :options="availableSampleTypes"
                :selected="form.sampleTypeId"
                placeholder="Tipo de muestra"
                @change="($event) => (form.sampleTypeId = $event)"
            /></d-col>
          </d-row>

          <hr />
          <d-row v-if="form.sampleTypeId" class="justify-content-md-left"
            ><d-col class="col-sm-6 cstm-rigth-3">
              <wizard-test-picker
                label="Biomarcadores"
                :tests="availableBiomarkers"
                :loaded-tests="studyTestsSelected"
                @tests-changed="
                  (tests) => {
                    form.studyTests = tests
                    studyTestsSelected = tests
                  }
                "
              />
            </d-col>
          </d-row>
          <!-- <d-row v-if="form.studyTests.length" class="justify-content-start"
            ><d-col class="cstm-col-sm-6">
              <v-input
                v-model="form.envelopeCode"
                name="envelopeCode"
                placeholder="Codigo de sobre"
                :has-error="$v.form.envelopeCode.$invalid"
                :force-error="errors.envelopeCode"
              /> </d-col
          ></d-row> -->
          <hr /></d-col
      ></d-row>
      <d-row v-if="step === 2" class="c-mb-8 to-left" fluid>
        <hr />

        <d-col class="align-items-center">
          <d-row class="justify-content-start">
            <d-col class="col-sm-3">
              <v-input
                v-model="form.patient.firstName"
                name="patientName"
                placeholder="Nombre del paciente"
                :has-error="$v.form.patient.firstName.$invalid"
                :force-error="errors.firstName"
              />
            </d-col>
            <hr class="separator" />

            <d-col class="col-sm-3">
              <v-input
                v-model="form.patient.lastName"
                name="patientSurName"
                placeholder="Apellido del paciente"
                :has-error="$v.form.patient.lastName.$invalid"
                :force-error="errors.lastName"
              /> </d-col
          ></d-row>
          <hr class="separator" />

          <d-row class="justify-content-start">
            <d-col class="col-sm-6">
              <v-select
                name="gender"
                :selected="form.patient.gender"
                :options="genderOptions"
                :has-error="$v.form.patient.gender.$invalid"
                :force-error="errors.gender"
                placeholder="Género"
                @change="($event) => (form.patient.gender = $event)"
              />
            </d-col>
            <hr class="separator" />

            <d-col
              class="col-sm-3 wizard-cstm-select cstm-rigth-2"
              style="margin-top: -0.5%"
            >
              <easy-datepicker
                :default-value="form.patient.birthDate"
                wizard-aesthethic
                :has-error="$v.form.patient.birthDate.$invalid"
                :force-error="errors.birthDate"
                @input="
                  (date) => {
                    form.patient.birthDate = date
                  }
                "
                @invalid-input="
                  ($event) => {
                    form.patient.birthDate = $event
                  }
                "
              /> </d-col
          ></d-row>
          <hr />
          <d-row class="justify-content-start">
            <d-col class="col-sm-3">
              <v-input
                v-model="form.patient.identityDocument"
                name="patientIdentityDocument"
                placeholder="Dni del paciente"
                :has-error="$v.form.patient.identityDocument.$invalid"
                :force-error="errors.identityDocument"
              />
            </d-col>
            <hr class="separator" />
            <d-col class="col-sm-3">
              <v-input
                v-model="form.patient.telephone"
                name="patientTelephone"
                :has-error="$v.form.patient.telephone.$invalid"
                :force-error="errors.telephone"
                placeholder="Teléfono del paciente"
                as-type="number"
              />
            </d-col>
          </d-row>
          <d-row class="justify-content-start"
            ><d-col class="cstm-col-sm-6">
              <insurance-details
                :insurance-details-options="insuranceDetailsOptions"
                :insurance-selected="form.patient.insuranceDetails"
                :has-error="$v.form.patient.insuranceDetails.$invalid"
                :force-error="errors.insuranceDetails"
                @input="($event) => (form.patient.insuranceDetails = $event)"
              /> </d-col
          ></d-row>

          <d-row class="justify-content-start">
            <!-- <d-col class="col-sm-6">
              <v-select
                name="smoker"
                :selected="form.patient.smoker"
                :options="smokerOptions"
                placeholder="Condición de fumador"
                @change="($event) => (form.patient.smoker = $event)"
              />
            </d-col> -->
            <hr class="separator" />

            <d-col class="col-sm-6">
              <v-input
                v-model="form.patient.email"
                name="patientEmail"
                :has-error="$v.form.patient.email.$invalid"
                :force-error="errors.email"
                placeholder="Email del paciente"
                type="email"
              /> </d-col
          ></d-row>
          <hr class="separator" />

          <d-row class="justify-content-start">
            <d-col class="col-sm-6">
              <v-select
                name="Province"
                :selected="form.patient.administrativeAreaLevel1"
                :options="administrativeAreaLevel1Options"
                placeholder="Provincia"
                :has-error="$v.form.patient.administrativeAreaLevel1.$invalid"
                :force-error="errors.administrativeAreaLevel1"
                @change="
                  ($event) => (form.patient.administrativeAreaLevel1 = $event)
                "
              />
            </d-col>
            <hr class="separator" />

            <d-col class="col-sm-3 cstm-rigth-2">
              <v-input
                v-model="form.patient.administrativeAreaLevel2"
                name="administrativeAreaLevel2"
                placeholder="Localidad"
                :has-error="$v.form.patient.administrativeAreaLevel2.$invalid"
                :force-error="errors.administrativeAreaLevel2"
              /> </d-col
          ></d-row>
          <hr /> </d-col
      ></d-row>
      <d-row v-if="step === 3" class="c-mb-8 to-left" fluid>
        <d-col class="align-items-center">
          <p style="color: var(--grey-3)">
            Por favor complete los siguientes datos
          </p>
          <div>
            <hr />
            <d-row class="justify-content-start">
              <d-col class="col-sm-3">
                <label>¿Tiene Testeos previos?</label>
                <d-form-checkbox
                  toggle
                  :class="{
                    accent: !errors.previousTesting,
                    danger: errors.previousTesting,
                  }"
                  :v-model="
                    form.patient.previousTesting === 'yes' ? true : false
                  "
                  :checked="
                    form.patient.previousTesting === 'yes' ? true : false
                  "
                  @change="
                    ($event) => {
                      if ($event == true) {
                        form.patient.previousTesting = 'yes'
                      } else {
                        form.patient.previousTesting = 'no'
                      }
                    }
                  "
                />
              </d-col>
            </d-row>
            <hr />
            <d-row class="justify-content-start">
              <d-col class="col-sm-3">
                <label>Historial familiar AME</label>
                <d-form-checkbox
                  v-model="form.patient.clinicalInformation.ameFamiliarHistory"
                  toggle
                  :class="{
                    accent: !errors.ameFamiliarHistory,
                    danger: errors.ameFamiliarHistory,
                  }"
                  :checked="
                    form.patient.clinicalInformation.ameFamiliarHistory
                  "
              /></d-col>
              <d-col class="col-sm-3">
                <label>Asíntomatico</label>
                <d-form-checkbox
                  v-model="form.patient.clinicalInformation.asymptomatic"
                  toggle
                  :class="{
                    accent: !errors.asymptomatic,
                    danger: errors.asymptomatic,
                  }"
                  :checked="form.patient.clinicalInformation.asymptomatic"
              /></d-col>
            </d-row>
            <hr />
            <d-row class="justify-content-start">
              <d-col class="col-sm-3">
                <label>Delecion SMN1</label>
                <d-form-checkbox
                  v-model="form.patient.clinicalInformation.smn1Deletion"
                  toggle
                  :class="{
                    accent: !errors.smn1Deletion,
                    danger: errors.smn1Deletion,
                  }"
                  :checked="form.patient.clinicalInformation.smn1Deletion"
              /></d-col>
              <d-col class="col-sm-3">
                <label>Debilidad muscular</label>
                <d-form-checkbox
                  v-model="form.patient.clinicalInformation.muscleWeakness"
                  toggle
                  :class="{
                    accent: !errors.muscleWeakness,
                    danger: errors.muscleWeakness,
                  }"
                  :checked="form.patient.clinicalInformation.muscleWeakness"
              /></d-col>
            </d-row>
            <hr />
            <d-row class="justify-content-start">
              <d-col class="col-sm-3 cstm-rigth">
                <v-input
                  v-model="form.patient.clinicalInformation.otherField"
                  toggle
                  placeholder="Otro"
                  name="otherField"
                  :has-error="
                    $v.form.patient.clinicalInformation.otherField.$invalid
                  "
                  :force-error="errors.otherField"
                />
              </d-col>
              <d-col class="col-sm-3">
                <v-input
                  v-model="form.patient.clinicalInformation.clinicalStage"
                  toggle
                  placeholder="Estadio clinico"
                  name="clinical_stage"
                  :has-error="
                    $v.form.patient.clinicalInformation.clinicalStage.$invalid
                  "
                  :force-error="errors.clinicalStage"
              /></d-col>
            </d-row>
            <d-row class="justify-content-start">
              <d-col class="col-sm-6 cstm-rigth">
                <textarea
                  v-model="form.patient.clinicalInformation.clinicalHistory"
                  class="form-control"
                  placeholder="Historia clínica"
                  name="clinical_history"
                  style="background: rgba(0, 0, 0, 0.05)"
                />
                <p v-if="errors.clinicalHistory" class="text-danger">
                  Este campo es obligatorio *
                </p>
              </d-col>
            </d-row>
            <hr />
          </div>
        </d-col>
      </d-row>
      <d-row v-if="step === 4" class="c-mb-8 to-left" fluid>
        <d-col class="align-items-center">
          <p style="color: var(--grey-3)">
            Para realizar el estudio, por favor incluya los siguientes items en
            el sobre:
          </p>
          <ul
            class="py-2 px-0 cstmConsent"
            style="
              border: 1px solid #eee;
              list-style-type: none;
              font-size: 16px;
            "
          >
            <li
              v-for="(required_item, idx) in testsDocumentation"
              :key="idx"
              class="px-3 py-1"
            >
              <i
                class="material-icons"
                style="font-size: 16px; color: #6943ff"
                >{{ required_item.icon }}</i
              >&nbsp;&nbsp;&nbsp;{{ required_item.text }}
            </li>
          </ul>

          <d-form-checkbox
            id="checkDocumentation"
            v-model="form.checkDocumentation"
            name="checkDocumentation"
            :class="{
              accent: !errors.checkDocumentation,
              danger: errors.checkDocumentation,
            }"
            @input="errors = checkValidationsPerSetp(step)"
            >Ya incluí los items nombrados en el sobre</d-form-checkbox
          >
          <p v-if="errors.checkDocumentation" class="text-danger">
            Este campo es obligatorio *
          </p>
          <d-form-checkbox
            id="has-consent-checkbox"
            v-model="form.has_consent"
            name="has-consent"
            :class="{
              accent: !errors.has_consent,
              danger: errors.has_consent,
              cstmConsent: true,
            }"
            @input="errors = checkValidationsPerSetp(step)"
          >
            Declaro haber obtenido y firmado los consentimientos informados del
            paciente y haberlos archivado debidamente, a fin de realizar los
            testeos. Manifiesto en forma libre mi consentimiento para el
            tratamiento de mis datos personales ("Datos Personales"), incluyendo
            datos sensibles, por parte de Biomakers con el fin de poder
            categorizarlos e incluso contactarme.
            <div v-for="item in informatedConsentsComputed" :key="item.id">
              <a :href="item.path" :to="item.path" target="_blank">{{
                item.label
              }}</a>
            </div>
          </d-form-checkbox>
          <p v-if="errors.has_consent" class="text-danger">
            Este campo es obligatorio *
          </p>
        </d-col></d-row
      >
      <d-row v-if="step === 5" class="c-mb-8 to-left" fluid>
        <d-col class="align-items-center">
          <!-- <h4 class="text-left">Punto de retiro</h4> -->
          <d-col class="col-sm-6" style="margin-left: -2%">
            <pickup-location-picker
              v-model="form.pickupLocationId"
              :doctor_id="form.doctorId"
              :disabled="sampleIsLiquid"
              principal-label="Dirección"
              @input="errors = checkValidationsPerSetp(step)"
              @completeItemChange="(item) => checkPickpupLocation(item)"
            />
            <p
              v-if="$v.form.pickupLocationId.$invalid && !form.pickupLocationId"
              class="text-danger"
            >
              Este campo es obligatorio *
            </p>
            <textarea
              v-model="form.additionalInformation"
              class="form-control"
              :placeholder="'Comentarios adicionales'"
              name="additionalInformation"
              style="background: rgba(0, 0, 0, 0.05)"
              @input="
                if (pickupLocationIsOther) {
                  checkValidationsPerSetp(step)
                }
              "
            />
          </d-col>

          <d-col class="col-sm-6" style="margin-left: -2%">
            <h6 class="">Horario de retiro</h6>
            <multi-button-picker
              :picker-options="pickupTimeRangeOptions"
              is-wizard
              @change="
                ($event) => {
                  form.pickupTimeRange = $event
                  errors = checkValidationsPerSetp(step)
                }
              "
            />
            <p v-if="errors.pickupTimeRange" class="text-danger">
              Este campo es obligatorio *
            </p>
          </d-col>
        </d-col></d-row
      >
    </div>
    <div class="inline">
      <div class="d-flex">
        <d-btn
          class="all-to-left general-bg text-danger"
          @click="$router.push({ name: NAMED_ROUTES.STUDIES })"
          >Cancelar</d-btn
        >
      </div>
      <div class="d-flex justify-content-end">
        <d-button-group class="all-to-left">
          <d-btn
            class="general-bg text-secondary"
            :disabled="step == 1"
            @click="step -= 1"
          >
            Volver</d-btn
          >
          <d-btn
            v-if="step < 5"
            class="general-bg text-purple"
            @click="canProceed(step)"
          >
            Continuar</d-btn
          >
          <d-btn
            v-if="step == 5"
            class="general-bg text-purple"
            @click="sendForm(step)"
          >
            Enviar</d-btn
          >
        </d-button-group>
      </div>
    </div>

    <d-modal v-if="openHelpModal == true">
      <d-modal-header class="justify-content-center" :close="false"
        ><h4 class="modal-title text-center modal-center-title">
          {{
            modalType == modalTypes.success
              ? helpTextSplitted[0]
              : 'Ocurrió un error'
          }}
        </h4>
        <svg
          class="justify-content-center"
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="60"
          fill="green"
          viewBox="0 0 24 24"
        >
          <path
            d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z"
          />
        </svg>
      </d-modal-header>
      <d-modal-body>
        <d-row class="justify-content-center">
          <div class="inline-block">
            <p
              v-for="val in helpTextSplitted.slice(1, helpTextSplitted.lenght)"
              :key="val.index"
            >
              {{ val }}
            </p>
            <d-btn
              v-if="modalType == modalTypes.success"
              class="btn-accent modal-center-title-2"
              @click="$router.push({ name: NAMED_ROUTES.STUDIES })"
              >Volver al inicio</d-btn
            >
          </div>
        </d-row>
      </d-modal-body>
    </d-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { getOptionsFor } from '@/utils'
import PlutonBaseMixin from '@/components/mixins/PlutonBaseMixin'
import { required, email, requiredIf } from 'vuelidate/lib/validators'

import VSelect from '@/components/Form/VSelect.vue'
import WizardTestPicker from '@/components/Form/WizardTestPicker.vue'
import VInput from '@/components/Form/VInput.vue'
import { NAMED_ROUTES } from '@/utils/constants'
import EasyDatepicker from '@/components/common/EasyDatepicker.vue'
import InsuranceDetails from '@/components/Form/InsuranceDetails.vue'
import MultiButtonPicker from '@/components/MultiButtonPicker.vue'
import PickupLocationPicker from '@/components/PickupLocationPicker.vue'
import axios from 'axios'

export default {
  name: 'RareDiseasesWizard',
  components: {
    VSelect,
    // WizardTumorPickerVue,
    WizardTestPicker,
    VInput,
    EasyDatepicker,
    InsuranceDetails,
    MultiButtonPicker,
    PickupLocationPicker,
  },
  mixins: [PlutonBaseMixin],
  data() {
    return {
      NAMED_ROUTES,
      step: 1,
      errors: {},
      pickupLocationIsOther: false,
      modalType: 'info',
      modalTypes: Object.freeze({
        info: 'info',
        error: 'error',
        success: 'success',
        other: 'other',
      }),
      studyTestsSelected: [],
      labelItems: [
        { title: 'Testeos a seleccionar' },
        { title: 'Datos del paciente' },
        { title: 'Identificación de AME' },
        { title: 'Documentación' },
        { title: 'Punto de retiro' },
      ],
      openHelpModal: false,
      helpText: '',
      form: {
        typeOfStudy: 'AME',
        checkDocumentation: false,
        has_consent: false,
        additionalInformation: '',
        envelopeCode: null,
        dont_have_envelope_code: true,
        studyTests: [],
        pickupLocationId: null,
        pickupTimeRange: null,
        doctorId: null,
        sampleTypeId: null,
        patient: {
          firstName: null,
          lastName: null,
          identityDocument: null,
          birthDate: null,
          gender: null,
          insuranceDetails: null,
          telephone: null,
          email: null,
          administrativeAreaLevel1: null,
          administrativeAreaLevel2: null,
          previousTesting: 'no',
          diagnosisStage: null,
          treatmentStage: null,
          previousTherapies: null,
          diagnosisLocation: null,
          diagnosis: null,
          smoker: null,
          currentStage: null,
          cancerSubtypeAtDiagnosis: null,
          drugsUsedForPriorTreatment: null,
          drugsUsedForActualTreatment: null,
          previousTreatments: null,
          ethinicity: null,
          clinicalInformation: {
            ameFamiliarHistory: false,
            asymptomatic: false,
            smn1Deletion: false,
            muscleWeakness: false,
            otherField: '',
            clinicalHistory: '',
            clinicalStage: '',
          },
        },
      },
    }
  },
  validations: {
    form: {
      additionalInformation: { required },
      // envelopeCode: { required },
      studyTests: { required },
      pickupLocationId: {
        required: requiredIf(function () {
          if (this.pickupLocationIsOther && !this.sampleIsLiquid) {
            return !this.form.additionalInformation
          }
          if (!this.pickupLocationIsOther && !this.sampleIsLiquid) {
            return true
          }
          return false
        }),
      },

      pickupTimeRange: { required },
      sampleTypeId: { required },
      checkDocumentation: { required },
      has_consent: { required },
      patient: {
        firstName: { required },
        lastName: { required },
        identityDocument: { required },
        birthDate: { required },
        gender: { required },
        insuranceDetails: { required },
        telephone: { required },
        email: { required, email },
        administrativeAreaLevel1: { required },
        administrativeAreaLevel2: { required },
        previousTesting: { required },
        clinicalInformation: {
          ameFamiliarHistory: { required },
          asymptomatic: { required },
          smn1Deletion: { required },
          muscleWeakness: { required },
          otherField: { required },
          clinicalHistory: { required },
          clinicalStage: { required },
        },
      },
    },
  },
  computed: {
    ...mapState('primaryTumors', ['primaryTumors']),
    ...mapState('testsGrouper', ['informatedConsents']),
    ...mapState('labelMappings', ['studyLabels', 'patientLabels']),
    ...mapState('biomarkers', ['AMESetting']),
    ...mapGetters('pharmas', ['pharmasEnabled']),

    ...mapState('dbFrontendConfigurations', ['frontEndConfigs']),
    ...mapGetters('countries', ['countries', 'sampleTypes']),

    helpTextSplitted() {
      return this.helpText.split('\n')
    },
    sampleIsLiquid() {
      const sampleType = this.sampleTypes.find(
        (sampleType) => sampleType.id === this.form.sampleTypeId
      )

      if (sampleType) return sampleType.code === 'liquid_sample'
      else return false
    },
    testsDocumentation() {
      const ret = [
        // { text: 'Informe de Anatomía Patológica', icon: 'assignment' },
      ]

      if (!this.form.studyTests || !this.form.studyTests.length) return ret

      this.form.studyTests.forEach((test) => {
        const pharma = this.pharmasEnabled.find(
          (pharma) => pharma.id === test['pharmaId']
        )

        const biomaker = this.availableBiomarkers.find(
          (biomaker) => test.testCode === biomaker.code
        )
        const testName = biomaker.name
        let docs
        const setting = this.availableBiomarkers
          .find((biomarker) => biomarker.id === test.testId)
          .allowedPharmas.find((pharma) => pharma.id === test.pharmaId)
          .customSettings.find((setting) =>
            [this.form.sampleTypeId, null].includes(setting.sampleTypeId)
          )
        if (pharma.requirements || (setting && setting.documentation))
          docs = { text: `${testName} (${pharma.name}):`, icon: 'email' }
        if (pharma.requirements)
          docs.text = `${docs.text} ${pharma.requirements}.`
        if (setting && setting.documentation)
          docs.text = `${docs.text} ${setting.documentation}.`
        if (docs) ret.push(docs)
      })
      return ret
    },
    availableBiomarkers() {
      if (!(this.form.doctorId && this.form.sampleTypeId)) {
        return []
      }
      const solidId = this.sampleTypes.find(
        (sampleType) => sampleType.code === 'solid'
      ).id

      let allowedSampleTypesId = [this.form.sampleTypeId]
      if (this.form.sampleTypeId === solidId)
        allowedSampleTypesId.push(undefined)
      return this.AMESetting.map((biomarker) => {
        biomarker = JSON.parse(JSON.stringify(biomarker))
        biomarker.allowedPharmas = biomarker.allowedPharmas.filter((pharma) =>
          pharma.customSettings.some(
            (setting) => setting.sampleTypeId === this.form.sampleTypeId
          )
        )
        return biomarker
      }).filter((biomarker) => biomarker.allowedPharmas.length > 0)
    },
    availableSampleTypes() {
      const sampleTypeIds = new Set()
      this.AMESetting.forEach((biomarker) =>
        biomarker.allowedPharmas.forEach((pharma) =>
          pharma.customSettings.forEach((setting) =>
            sampleTypeIds.add(setting.sampleTypeId)
          )
        )
      )
      const options = this.sampleTypes
        .map((sampleType) => ({ value: sampleType.id, text: sampleType.label }))
        .filter((sampleType) => sampleTypeIds.has(sampleType.value))

      if (options.length === 1)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.sampleTypeId = options[0].value

      return options
    },

    diagnosisOptions() {
      return getOptionsFor(this.patientLabels.diagnosis)
    },
    diagnosisStageOptions() {
      return getOptionsFor(this.patientLabels.diagnosisStage)
    },
    treatmentStageOptions() {
      return getOptionsFor(this.patientLabels.treatmentStage)
    },
    previousTestingOptions() {
      return getOptionsFor(this.patientLabels.previousTesting)
    },
    genderOptions() {
      return getOptionsFor(this.patientLabels.gender)
    },
    pickupTimeRangeOptions() {
      return getOptionsFor(this.studyLabels.pickupTimeRange)
    },
    insuranceDetailsConfigurated() {
      return this.$store.state.dbFrontendConfigurations.frontEndConfigs.find(
        (config) =>
          config.frontendFieldId == 'insurance_details' &&
          config.countryId == this.$auth.user().country.id
      )
    },
    insuranceDetailsOptions() {
      if (this.insuranceDetailsConfigurated) {
        let arrayedJSON =
          this.insuranceDetailsConfigurated.content &&
          typeof this.insuranceDetailsConfigurated.content === 'object'
            ? Object.keys(this.insuranceDetailsConfigurated.content).map(
                (value) => ({
                  value: value,
                  text: this.insuranceDetailsConfigurated.content[value],
                })
              )
            : []
        return arrayedJSON
      }
      return {}
    },
    smokerOptions() {
      return getOptionsFor(this.patientLabels.smokerStatus)
    },
    administrativeAreaLevel1Options() {
      return this.frontEndConfigs
        .find(
          (config) =>
            config.frontendFieldId == 'administrativeAreasLevel1' &&
            config.countryId == this.$auth.user().country.id
        )
        .content.areas.filter((area) => !!area.value)
    },

    informatedConsentsComputed() {
      let informatedConsentsBMK = this.informatedConsents.filter((CI) => {
        return CI.testsGrouper.biomarkersTestsConfig.some((val) =>
          this.AMESetting.some((s) => s.id == val.biomarkerId)
        )
      })
      if (this.form.sampleTypeId) {
        return informatedConsentsBMK.filter((CI) =>
          CI.testsGrouper.biomarkersTestsConfig.some((config) =>
            this.form.studyTests.some(
              (test) =>
                config.biomarkerId == test.testId &&
                config.pharmaId == test.pharmaId
            )
          )
        )
      }
      return []
    },
  },
  async mounted() {
    this.getPrimaryTumors()
    this.fetchPatientLabels()
    this.fetchStudyLabels()
    await this.getAMESettings()
    await this.getFrontEndConfigurationsForCreateStudy()
    await this.getSampleTypes()
    await this.getPharmas()

    this.getInformatedConsents()

    this.form.doctorId = this.$auth.user().relationId
  },
  methods: {
    ...mapActions('pharmas', ['getPharmas']),
    ...mapActions('primaryTumors', ['getPrimaryTumors']),
    ...mapActions('labelMappings', [
      'fetchStudyLabels',
      'fetchPatientLabels',
      //   'getPreScreeningLabels',
    ]),
    ...mapActions('biomarkers', ['getAMESettings']),
    ...mapActions('dbFrontendConfigurations', [
      'getFrontEndConfigurationsForCreateStudy',
    ]),
    ...mapActions('countries', ['getSampleTypes']),
    ...mapActions('testsGrouper', ['getInformatedConsents']),
    checkPickpupLocation(item) {
      if (item.name == 'Otro') {
        this.pickupLocationIsOther = true
      } else {
        this.pickupLocationIsOther = false
      }
    },
    checkValidOtherField() {
      if (
        this.form.additionalInformation.trim().length === 0 &&
        this.form.additionalInformation.length === 0 &&
        this.form.pickupLocationId === null &&
        !this.sampleIsLiquid
      ) {
        // if Other field is empty and it's selected Otro in "Punto de retiro"
        return 'invalid'
      } else if (
        this.form.additionalInformation.length > 0 &&
        this.form.additionalInformation.trim().length === 0 &&
        this.form.pickupLocationId === null &&
        !this.sampleIsLiquid
      ) {
        // there are blank spaces and it's selected Otro in Punto de retiro
        return 'invalid'
      } else {
        return 'valid'
      }
    },
    checkValidationsPerSetp(step) {
      switch (step) {
        case 1:
          return {
            sampleTypeId: this.$v.form.sampleTypeId.$invalid,
            studyTests: this.$v.form.studyTests.$invalid,
            // envelopeCode: this.$v.form.envelopeCode.$invalid,
          }
        case 2:
          return {
            firstName: this.$v.form.patient.firstName.$invalid,
            lastName: this.$v.form.patient.lastName.$invalid,
            gender: this.$v.form.patient.gender.$invalid,
            birthDate: this.$v.form.patient.birthDate.$invalid,
            identityDocument: this.$v.form.patient.identityDocument.$invalid,
            telephone: this.$v.form.patient.telephone.$invalid,
            insuranceDetails: this.$v.form.patient.insuranceDetails.$invalid,
            email: this.$v.form.patient.email.$invalid,
            administrativeAreaLevel1:
              this.$v.form.patient.administrativeAreaLevel1.$invalid,
            administrativeAreaLevel2:
              this.$v.form.patient.administrativeAreaLevel2.$invalid,
          }
        case 3:
          return {
            smn1Deletion:
              this.$v.form.patient.clinicalInformation.smn1Deletion.$invalid,
            ameFamiliarHistory:
              this.$v.form.patient.clinicalInformation.ameFamiliarHistory
                .$invalid,
            asymptomatic:
              this.$v.form.patient.clinicalInformation.asymptomatic.$invalid,
            muscleWeakness:
              this.$v.form.patient.clinicalInformation.muscleWeakness.$invalid,
            clinicalHistory:
              this.$v.form.patient.clinicalInformation.clinicalHistory.$invalid,
            clinicalStage:
              this.$v.form.patient.clinicalInformation.clinicalStage.$invalid,
            otherField:
              this.$v.form.patient.clinicalInformation.otherField.$invalid,
            previousTesting: this.$v.form.patient.previousTesting.$invalid,
          }
        case 4:
          return {
            checkDocumentation: !this.form.checkDocumentation,
            has_consent: !this.form.has_consent,
          }
        case 5:
          return {
            pickupLocationId: !this.pickupLocationIsOther
              ? this.$v.form.pickupLocationId.$invalid
              : !!this.form.additionalInformation,
            pickupTimeRange: this.$v.form.pickupTimeRange.$invalid,
          }
        default:
          return false
      }
    },

    async canProceed(step) {
      await this.$v.$touch()
      if (this.isStepValid(step)) {
        this.step += 1
        this.errors = {}
      } else {
        this.errors = this.checkValidationsPerSetp(step)
      }
    },
    async sendForm(step) {
      if (this.step == 5 && this.isStepValid(step)) {
        this.setLoading(true)
        this.errors = {}
        await axios({
          method: 'POST',
          url: '/api2/study/AME_study',
          data: { ...this.form },
        })
          .then((response) => {
            this.setLoading(false)
            if (response.status == 200) {
              this.openHelpModal = true
              this.modalType = this.modalTypes.success
              this.helpText = response.data.message
              this.helpText =
                this.helpText + '\n' + 'En breve nos contactaremos con usted'
            } else if (response.status != 200) {
              this.openHelpModal = true
              this.modalType = this.modalTypes.error
              this.helpText = `Ocurrió un error con el envío de la solicitud. Envíe este codigo a soporte:${response.status_code} con el siguiente mensaje de error: ${response.data.errors}`
            }
          })
          .catch((error) => {
            this.errors = { status_code: 500, error: error }
          })
      } else {
        this.errors = this.checkValidationsPerSetp(step)
      }
    },
    isStepValid(step) {
      switch (step) {
        case 1:
          return (
            !this.$v.form.sampleTypeId.$invalid &&
            !this.$v.form.studyTests.$invalid
            // &&
            // !this.$v.form.envelopeCode.$invalid
          )
        case 2:
          return (
            !this.$v.form.patient.firstName.$invalid &&
            !this.$v.form.patient.lastName.$invalid &&
            !this.$v.form.patient.gender.$invalid &&
            !this.$v.form.patient.birthDate.$invalid &&
            !this.$v.form.patient.identityDocument.$invalid &&
            !this.$v.form.patient.telephone.$invalid &&
            !this.$v.form.patient.insuranceDetails.$invalid &&
            !this.$v.form.patient.email.$invalid &&
            !this.$v.form.patient.administrativeAreaLevel1.$invalid &&
            !this.$v.form.patient.administrativeAreaLevel2.$invalid
          )
        case 3:
          return (
            !this.$v.form.patient.clinicalInformation.smn1Deletion.$invalid &&
            !this.$v.form.patient.clinicalInformation.ameFamiliarHistory
              .$invalid &&
            !this.$v.form.patient.clinicalInformation.asymptomatic.$invalid &&
            !this.$v.form.patient.clinicalInformation.muscleWeakness.$invalid &&
            !this.$v.form.patient.clinicalInformation.clinicalHistory
              .$invalid &&
            !this.$v.form.patient.clinicalInformation.clinicalStage.$invalid &&
            !this.$v.form.patient.clinicalInformation.otherField.$invalid &&
            !this.$v.form.patient.previousTesting.$invalid
          )
        case 4:
          return this.form.checkDocumentation && this.form.has_consent

        case 5:
          return (
            !this.$v.form.pickupLocationId.$invalid &&
            !this.$v.form.pickupTimeRange.$invalid
          )
        default:
          return false
      }
    },
  },
}
</script>
<style lang="scss">
.custom-number {
  color: white;
  background-color: var(--purple);
  font-weight: bold;
  font-size: 15px;
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 5px;
}
.custom-number-disabled {
  color: white;
  background-color: var(--grey-1);
  font-weight: bold;
  font-size: 15px;
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 5px;
}

.cstm-left {
  padding-left: 15rem;
  color: var(--grey-1);
}

.separator {
  display: none;
}
.modal-center-title {
  position: relative;
  align-self: center;
  padding-right: 2%;
}
.modal-center-title-3 {
  position: relative;
  left: 2%;
}
.modal-center-title-2 {
  position: relative;
  left: 20%;
}
/* Tamaño de pantalla grande (por ejemplo, escritorio grande) */
@media (min-width: 1200px) {
  .cstmConsent {
    width: 45%;
  }
  .tabs-container {
    display: inline;
    left: 10%;
    position: relative;
  }
  .c-btn {
    margin-right: 2.5%;
  }
  .all-to-left {
    position: relative;
    // margin-left: 65rem;
    right: -350%;
    // bottom: 12%;
  }
  .to-left {
    padding-left: 25rem;
  }
  .cstm-rigth {
    margin-left: -2%;
  }
  .cstm-rigth-2 {
    margin-left: -25%;
  }
  .cstm-rigth-3 {
    margin-left: -1.5%;
  }
  .cstm-col-sm-6 {
    max-width: 50%;
  }
  .cstm-col-sm-3 {
    width: 30%;
    max-width: 30%;
  }
}

/* Tamaño de pantalla mediano (tabletas en orientación horizontal o laptops más pequeñas) */
@media (max-width: 1199px) and (min-width: 992px) {
  .cstmConsent {
    width: 45%;
  }
  .tabs-container {
    display: inline;
    left: 10%;
    position: relative;
  }
  .c-btn {
    margin-right: 2.5%;
  }
  .all-to-left {
    margin-left: 10rem;
  }
  .to-left {
    padding-left: 15rem;
  }
  .cstm-rigth {
    margin-left: -15%;
  }
  .cstm-rigth-2 {
    margin-left: -25%;
  }
  .cstm-col-sm-6 {
    width: 75%;
  }
  .cstm-col-sm-3 {
    width: 30%;
  }
  .cstm-rigth-3 {
    margin-left: -2%;
  }
}

/* Tamaño de pantalla pequeño (tabletas en orientación vertical o teléfonos grandes) */
@media (max-width: 991px) and (min-width: 768px) {
  .cstmConsent {
    width: 45%;
  }
  .tabs-container {
    display: inline;
    left: 10%;
    position: relative;
  }
  .c-btn {
    margin-right: 2.5%;
  }
  .all-to-left {
    margin-left: 10rem;
  }
  .to-left {
    padding-left: 5rem;
  }
  .cstm-rigth-2 {
    margin-left: -8%;
  }
  .cstm-rigth {
    margin-left: -10%;
  }
  .cstm-col-sm-6 {
    width: 100%;
  }
  .cstm-col-sm-3 {
    width: 60%;
  }
  .wizard-cstm-select {
    position: relative;
    left: 25%;
    width: 50%;
    margin-top: 7%;
  }
  .cstm-rigth-3 {
    margin-left: -2.5%;
  }
}

/* Tamaño de pantalla muy pequeño (teléfonos pequeños) */

@media (max-width: 767px) {
  .cstmConsent {
    width: 95%;
  }
  .tabs-container {
    display: grid;
    /* left: 10%; */
    position: relative;
    justify-items: start;
  }
  .c-btn {
    margin-right: -15%;
  }
  .principalDiv {
    width: 95%;
  }
  .all-to-left {
    margin-left: -1rem;
  }
  .to-left {
    padding-left: 0rem;
  }
  .cstm-rigth {
    margin-left: 0%;
  }
  .separator {
    display: block;
    width: 100%;
    border: 0;
    border-top: 1px solid #ddd;
    margin: 1rem 0;
  }
  .cstm-col-sm-6 {
    width: 100%;
  }
  .cstm-col-sm-3 {
    width: 60%;
  }
  .cstm-rigth-3 {
    margin-left: -3.5%;
  }
}

.border-custom {
  border: 1px solid #ccc;
}
hr {
  visibility: hidden;
}
.custom-control-input:focus ~ .custom-control-label::before {
  border-color: var(--purple) !important;
  box-shadow: 0 0 0 0.2rem var(--purple-light) !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--purple) !important;
  background-color: var(--purple) !important;
}

.custom-control-input:active ~ .custom-control-label::before {
  background-color: var(--purple) !important;
  border-color: var(--purple) !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: var(--purple) !important;
}

.custom-control-input-green:not(:disabled):active
  ~ .custom-control-label::before {
  background-color: var(--purple) !important;
  border-color: var(--purple) !important;
}

.text-purple {
  color: var(--purple) !important;
}
.general-bg {
  background: #f5f6f8 !important;
  border-color: #f5f6f8 !important;
}
.c-mb-8 {
  justify-content: center;
  display: flex;
}
</style>

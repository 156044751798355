<template>
  <div>
    <d-input-group v-if="!valueSelected" class="mb2">
      <v-input
        v-model="searchInsuranceDetailsFieldText"
        placeholder="Seleccione cobertura médica"
        class="custom-wizard-input"
        name="insuranceSearch"
        :has-error="hasError"
        :force-error="forceError"
        @input="searchInsuranceDetails($event)"
      />
    </d-input-group>
    <d-input-group v-if="!!valueSelected">
      <v-input
        v-if="valueSelected"
        id="insuranceResult"
        v-model="
          insuranceDetailsOptions.find((i) => i.value == valueSelected).text
        "
        placeholder="Cobertura médica"
        class="custom-wizard-input-2"
        name="insuranceResult"
        disabled
      />
      <d-input-group-addon v-if="valueSelected" append>
        <d-button class="btn-accent purple-button" @click="valueSelected = null"
          ><i class="material-icons justify-content-center">close</i></d-button
        >
      </d-input-group-addon>
    </d-input-group>
    <d-list-group
      v-if="
        searchInsuranceDetailsFieldText &&
        insuranceDetailsSearchResult.length > 0 &&
        !valueSelected
      "
      class="resultList"
    >
      <ul v-for="result in insuranceDetailsSearchResult" :key="result.value">
        <a @click="() => (valueSelected = result.value)">
          <d-list-group-item>{{ result.text }}</d-list-group-item>
        </a>
      </ul>
    </d-list-group>
  </div>
</template>

<script>
import VInput from '@/components/Form/VInput.vue'

export default {
  components: {
    VInput,
  },
  props: {
    insuranceDetailsOptions: { type: Array, required: true, default: () => [] },
    insuranceSelected: { type: String, required: false, default: null },
    hasError: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    forceError: {
      type: Boolean,
      default: () => false,
      required: false,
    },
  },
  data() {
    return {
      insuranceDetailsSearchResult: [],
      searchInsuranceDetailsFieldText: '',
      valueSelected: null,
    }
  },
  watch: {
    valueSelected(val) {
      this.$emit('input', val)
    },
  },
  mounted() {
    if (this.insuranceSelected) this.valueSelected = this.insuranceSelected
  },
  methods: {
    searchInsuranceDetails(value) {
      setTimeout(() => {
        this.insuranceDetailsSearchResult = this.insuranceDetailsOptions.filter(
          (insurance) =>
            insurance.text.includes(value) ||
            insurance.text.toLowerCase().includes(value) ||
            insurance.text.toUpperCase().includes(value)
        )
      }, 5)
      return
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-wizard-input {
  background-color: var(--grey1);
  height: 50%;
  position: relative;
  width: 100%;
}

@media (min-width: 1200px) {
  .custom-wizard-input-2 {
    background-color: var(--grey1);
    height: 50%;
    position: relative;
    width: 85%;
  }
  .insuranceResult {
    width: 75%;
  }
  .purple-button {
    background-color: var(--purple);
    border-color: var(--purple);
    margin-bottom: 1.46rem;
    .material-icons {
      font-size: 12px;
    }
  }
  .resultList {
    max-height: 200px; /* Ajusta la altura máxima según tus necesidades */
    overflow-y: auto;
    border: 1px solid #ccc; /* Añade un borde para mayor claridad */
    padding: 1.5%;
    padding-right: 6%;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .custom-wizard-input-2 {
    background-color: var(--grey1);
    height: 50%;
    position: relative;
    width: 75%;
  }

  .purple-button {
    background-color: var(--purple);
    border-color: var(--purple);
    margin-bottom: 1.26rem;
    margin-right: 25%;
    right: 95%;
    position: relative;
    .material-icons {
      font-size: 12px;
    }
  }
  .resultList {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 1.5%;
    padding-right: 26%;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .custom-wizard-input-2 {
    background-color: var(--grey1);
    height: 50%;
    position: relative;
    width: 75%;
  }

  .purple-button {
    background-color: var(--purple);
    border-color: var(--purple);
    margin-bottom: 1.26rem;
    margin-right: 25%;
    right: 95%;
    position: relative;
    .material-icons {
      font-size: 12px;
    }
  }
  .resultList {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 1.5%;
    padding-right: 26%;
  }
}
@media (max-width: 767px) {
  .custom-wizard-input-2 {
    background-color: var(--grey1);
    height: 50%;
    position: relative;
    width: 70%;
  }

  .purple-button {
    background-color: var(--purple);
    border-color: var(--purple);
    margin-bottom: 1.26rem;
    margin-right: 25%;
    right: 45%;
    position: relative;
    .material-icons {
      font-size: 14px;
    }
  }
  .resultList {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 1.5%;
    padding-right: 26%;
  }
}
</style>

<template>
  <div
    :class="{
      'has-error': (hasError && error) || (forceError && error),
      'is-disabled': disabled,
    }"
  >
    <!-- eslint-disable-->
    <select
      v-model="selected"
      class="cstm-form-select"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="animateLabel"
    >
      <option :value="null" disabled selected hidden>
        {{ placeholder }}
      </option>
      <option v-for="opt in options" :key="opt.value" :value="opt.value">
        {{ opt.text }}
      </option>
    </select>
    <label
      :id="`animatedLabel-${placeholder}`"
      class="animatedLabel"
      :for="name"
      :data-content="placeholder"
    >
      <span :id="`hiddenVisually-${placeholder}`" class="hiddenVisually">{{
        placeholder
      }}</span>
    </label>
    <span class="text-bottom">{{ bottomText }}</span>
    <span v-if="hasError || forceError" class="text-bottom">{{ error }}</span>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    type: {
      type: String,
      default: 'text',
    },
    hasError: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    forceError: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    help: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: String,
      default: () => '',
      required: false,
    },
  },
  data() {
    return {
      error: '',
    }
  },

  computed: {
    bottomText() {
      return this.help
    },
  },
  watch: {
    selected(val) {
      this.$emit('change', val)
    },

    hasError(val) {
      if (val == true) {
        this.error = 'Este campo es requerido'
      } else {
        this.error = null
      }
    },
    forceError(val) {
      if (val == true) {
        this.error = 'Este campo es requerido'
      } else {
        this.error = null
      }
    },
  },
  mounted() {
    if (this.selected) this.animateLabel()
  },
  methods: {
    animateLabel() {
      let label = document.getElementById(`animatedLabel-${this.placeholder}`)
      let span = document.getElementById(`hiddenVisually-${this.placeholder}`)
      if (!label.classList.contains('animate'))
        label.classList.toggle('animate')
      if (!span.classList.contains('animate')) span.classList.toggle('animate')
    },
  },
}
</script>
<style lang="scss" scoped>
.has-error {
  & select {
    border-bottom-color: #b00020;
    color: #b00020;
  }
  & .text-bottom,
  label {
    color: #b00020;
  }
}
.hiddenVisually {
  overflow: hidden;
  position: relative;
  bottom: 2%;
  left: 15%;
  opacity: 0;
}
.hiddenVisually.animate {
  opacity: 1;
  color: var(--gray-3);
  position: absolute;
  top: 25%;
  left: 5%;
  height: 100%;
}
@media (min-width: 1200px) {
  .cstm-form-select {
    width: 48%;
    max-width: 70%;
    background: rgba(0, 0, 0, 0.05);
    padding: 1.4rem 1rem 0.45rem 0.5rem;
    font-size: 1rem;
    color: black;
    border-width: 0 0 2px;
    border-radius: 5px 5px 0 0;
    height: 65%;

    &::placeholder {
      color: rgba(0, 0, 0, 0);
    }

    &:focus {
      outline: none;
    }
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .cstm-form-select {
    width: 75%;
    background: rgba(0, 0, 0, 0.05);
    padding: 1.4rem 1rem 0.45rem 0.5rem;
    font-size: 1rem;
    color: black;
    border-width: 0 0 2px;
    border-radius: 5px 5px 0 0;
    height: 65%;

    &::placeholder {
      color: rgba(0, 0, 0, 0);
    }

    &:focus {
      outline: none;
    }
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .cstm-form-select {
    width: 85%;
    background: rgba(0, 0, 0, 0.05);
    padding: 1.4rem 1rem 0.45rem 0.5rem;
    font-size: 1rem;
    color: black;
    border-width: 0 0 2px;
    border-radius: 5px 5px 0 0;
    height: 65%;

    &::placeholder {
      color: rgba(0, 0, 0, 0);
    }

    &:focus {
      outline: none;
    }
  }
}
@media (max-width: 767px) {
  .cstm-form-select {
    width: 85%;
    background: rgba(0, 0, 0, 0.05);
    padding: 1.4rem 1rem 0.45rem 0.5rem;
    font-size: 1rem;
    color: black;
    border-width: 0 0 2px;
    border-radius: 5px 5px 0 0;
    height: 65%;

    &::placeholder {
      color: rgba(0, 0, 0, 0);
    }

    &:focus {
      outline: none;
    }
  }
}

div {
  position: relative;
}
label {
  position: absolute;
  // left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.2s ease, top 0.2s ease;
}
.animatedLabel {
  overflow: hidden;
  position: absolute;
  bottom: 2%;
  left: 15%;
}

.animatedLabel.animate {
  transform: translateY(-90%); // Mantiene el label centrado
  transition: transform 0.2s ease, top 0.2s ease;
  left: 0.05%;
  top: 30%; // Ajusta para centrar verticalmente
  width: 30%;
  opacity: 1;
  // color: #000;
}

.animatedLabel.animate.animate-in {
  top: 1%;
}

.text-bottom {
  padding-left: 2.8rem;
  font-size: 0.8rem;
  height: 1rem;
  padding-top: 3%;
  position: absolute;
  bottom: 0rem;
  left: -1rem;
  color: #b00020;
}

.is-disabled {
  opacity: 0.8;
}
</style>
